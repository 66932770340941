import { Badge } from 'lib/components/Badge';
import { NewspaperOrderStatus } from 'lib/types/newspaperOrder';
import { SearchableNewspaperOrderRecord } from 'lib/types/searchable';
import { Product } from 'lib/enums';
import moment from 'moment';
import { ReactElement } from 'react';
import { capitalizeWord, decodeHtml } from 'lib/utils/strings';
import { NoSymbolIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import { logAndCaptureMessage } from 'utils';
import { capitalize } from 'lodash';
import LinkTo from 'components/LinkTo';
import { AdsTableRowPublicationDateColumn } from './AdsTableRowPublicationDateColumn';
import {
  CustomerTypeString,
  VerificationFilterString
} from './filters/helpers';
import { getBadgeStatusForNewspaperOrderStatus } from './helpers';
import { AdsTableColumns } from './types';
import OrderTableRowActions from './OrderTableRowActions';

type AdsTableRowProps<T extends SearchableNewspaperOrderRecord> = {
  item: T;
  columns: AdsTableColumns[];
};

function getLink(item: SearchableNewspaperOrderRecord) {
  if (item.newspaperorderstatus === NewspaperOrderStatus.DRAFT) {
    if (item.ordertype === Product.Obituary) {
      return `/obituaries/place/${item.orderid}`;
    }
    if (item.ordertype === Product.Classified) {
      return `/classifieds/place/${item.orderid}`;
    }

    return '';
  }

  return `/${item.ordertype.toLowerCase()}/${item.orderid}`;
}

const searchableNewspaperOrderRecordToFormattedAd = (
  ad: SearchableNewspaperOrderRecord,
  columns: AdsTableColumns[]
): Record<string, { component: ReactElement | undefined; link: boolean }> => {
  const deceasedName = decodeHtml(ad.deceasedname ?? '');
  // Since we haven'd defined classified props yet, the field we use for the listing name may change
  const listingName = decodeHtml(ad.title ?? '');
  console.log(decodeHtml(listingName), 'LN');
  const adDeadline = (
    <Badge>{moment(Number(ad.addeadline)).format('MMM D, YYYY')}</Badge>
  );
  // if the obit was placed by a funeral home, we want to show that funeral home as the customer
  // if the ad was placed by an individual, the funeral home name does not refer to the customer,
  // (i.e., the person paying for the order) so we want to use the filer's name instead
  // TODO: (Zubaria) show here classified customer name (if customer exists)
  const customerName = ad.hasadvertiserorg
    ? ad.funeralhomename ?? ''
    : ad.filerfirstname && ad.filerlastname
    ? `${ad.filerfirstname} ${ad.filerlastname}`
    : '';
  const category = (
    <div className="flex gap-2">
      <Badge>{ad.filingtype}</Badge>
      {ad.publishingmedium && <Badge>{capitalize(ad.publishingmedium)}</Badge>}
    </div>
  );
  const publicationDates = (
    <AdsTableRowPublicationDateColumn publishingdates={ad.publishingdates} />
  );
  const customerType = (
    <Badge>
      {ad.hasadvertiserorg
        ? CustomerTypeString.FUNERAL_HOME
        : CustomerTypeString.INDIVIDUAL}
    </Badge>
  );
  const verification = (
    <Badge
      startIcon={
        ad.isverified ? (
          <CheckCircleIcon className="w-3 h-3" />
        ) : (
          <NoSymbolIcon className="w-3 h-3" />
        )
      }
    >
      {ad.isverified
        ? VerificationFilterString.COMPLETE
        : VerificationFilterString.INCOMPLETE}
    </Badge>
  );
  const publication = <Badge>{ad.publishername}</Badge>;
  const status = (
    <Badge
      status={getBadgeStatusForNewspaperOrderStatus(
        ad.newspaperorderstatus as NewspaperOrderStatus
      )}
    >
      {capitalizeWord(ad.newspaperorderstatus)}
    </Badge>
  );

  const newspaperName = ad.publishername;

  const actions = <OrderTableRowActions orderId={ad.orderid} />;

  const row = columns.reduce((accRow, column) => {
    switch (column) {
      case AdsTableColumns.DECEASED_NAME:
        return {
          ...accRow,
          deceasedName: { component: deceasedName, link: true }
        };
      case AdsTableColumns.AD_DEADLINE:
        return { ...accRow, adDeadline: { component: adDeadline, link: true } };
      case AdsTableColumns.CUSTOMER_NAME:
        return {
          ...accRow,
          customerName: { component: customerName, link: true }
        };
      case AdsTableColumns.NEWSPAPER_NAME:
        return {
          ...accRow,
          newspaperName: { component: newspaperName, link: true }
        };
      case AdsTableColumns.CATEGORY:
        return { ...accRow, category: { component: category, link: true } };
      case AdsTableColumns.PUBLICATION_DATE:
        return {
          ...accRow,
          publicationDates: { component: publicationDates, link: true }
        };
      case AdsTableColumns.CUSTOMER_TYPE:
        return {
          ...accRow,
          customerType: { component: customerType, link: true }
        };
      case AdsTableColumns.VERIFICATION:
        return {
          ...accRow,
          verification: { component: verification, link: true }
        };
      case AdsTableColumns.PUBLICATION:
        return {
          ...accRow,
          publication: { component: publication, link: true }
        };
      case AdsTableColumns.STATUS:
        return { ...accRow, status: { component: status, link: true } };
      case AdsTableColumns.LISTING_NAME:
        return {
          ...accRow,
          listingName: { component: listingName, link: true }
        };
      case AdsTableColumns.ACTIONS:
        return { ...accRow, actions: { component: actions, link: false } };
      default:
        logAndCaptureMessage('Unexpected column in ads table', { column });
        return accRow;
    }
  }, {});
  return row;
};

export default function AdsTableRow<T extends SearchableNewspaperOrderRecord>({
  item,
  columns
}: AdsTableRowProps<T>) {
  const linkUrl = getLink(item);

  // Construct table rows data
  const formattedItem = searchableNewspaperOrderRecordToFormattedAd(
    item,
    columns
  );

  return (
    <>
      {Object.values(formattedItem).map((value, index) => (
        <td key={index}>
          {value.link ? (
            <LinkTo href={linkUrl}>{value.component ?? ''}</LinkTo>
          ) : (
            value.component ?? ''
          )}
        </td>
      ))}
    </>
  );
}
