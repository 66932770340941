export enum ColumnService {
  /** Column Admin */
  ADMIN = 'admin',

  /** Static file storage */
  FILE_STORAGE = 'file-storage',

  /** File transformation (cropping, upscaling, resizing,..) */
  FILE_TRANSFORMATION = 'file-transformation',

  /** Our primary Firestore database */
  DATABASE = 'database',

  /** Automated affidavits */
  AFFIDAVITS = 'affidavits',

  /** AFFinity X Service */
  AFFINITY_X = 'affinity-x',

  /** inApp notifications */
  APPLICATION_NOTIFICATIONS = 'application-notifications',

  /** Automated notice placement */
  AUTOMATED_NOTICE_PLACEMENT = 'automated-notice-placement',

  /** Bulk invoicing (v1 and v2) */
  BULK_INVOICES = 'bulk-invoices',

  /** Deadline scheduling and syncing */
  DEADLINES = 'deadlines',

  /** Elastic */
  ELASTIC = 'elastic',

  /** Check Reconciliation, ACH reconciliation, etc */
  FINANCIAL_RECONCILIATION = 'financial-reconciliation',

  /** Authentication and user management */
  AUTH_AND_USER_MANAGEMENT = 'authentication-and-user-management',

  /** Affidavits, invoices/receipts, proofs */
  FILE_GENERATION = 'file-generation',

  /** Inbound FTP to our FileMage server */
  FTP = 'ftp',

  /** Secret access/management for our backend */
  FUNCTIONS_CONFIG = 'functions-config',

  /** InDesign Service */
  INDESIGN = 'indesign',

  /** General integrations infrastructure */
  INTEGRATIONS = 'integrations',

  /** Lee Adpoint integration */
  INTEGRATIONS_LEE_ADPOINT = 'integrations-lee-adpoint',

  /** SCNG Naviga integration */
  INTEGRATIONS_SCNG = 'integrations-scng',

  /** Launch Darkly Service */
  LAUNCH_DARKLY_SERVICE = 'launch-darkly-service',

  /** Placement and edit flow for orders */
  ORDER_PLACEMENT = 'order-placement',

  /** Order details views, table views, cancellation flow, etc. */
  ORDER_MANAGEMENT = 'order-management',

  /** All payment processing (Stripe, Elavon, Payway, Dwolla, etc.) */
  PAYMENTS = 'payments',

  /** Custom pagination (i.e. code that's tied to /pagination) */
  PAGINATION = 'pagination',

  /** Registered Agents API */
  REGISTERED_AGENTS = 'registered-agents',

  /** Support Bot Context */
  SUPPORT_BOT = 'support-bot',

  /** All other issues */
  UNKNOWN = 'unknown',

  /** Placing notices on the web */
  WEB_PLACEMENT = 'web-placement',

  /** Organization and user settings features */
  SETTINGS_MANAGEMENT = 'settings-management',

  /** Display sites (public notices, obits and classifieds) */
  DISPLAY_SITES = 'display-sites',

  /** CI and deployment */
  CI_CD = 'ci-and-deployment',

  /** General migration errors */
  DATA_MIGRATION = 'data-migration',

  /** Automated bulk download at deadline */
  AUTOMATED_BULK_DOWNLOAD = 'auto-bulk-download',

  /** Firestore document listeners (onWrite, onUpdate,...) */
  FIRESTORE_LISTENERS = 'firestore-listeners',

  /** Organization management includes duplicating organizations, adding users and adding upload ID */
  ORGANIZATION_MANAGEMENT = 'organization-management',

  /** Template styles management */
  TEMPLATE_STYLES_MANAGEMENT = 'template-styles-management',

  /** Automated uploads to external state-wide sites */
  EXTERNAL_UPLOADS = 'external-uploads',

  /** Frontend routing (URLs, redirects, etc.) */
  FRONTEND_ROUTING = 'frontend-routing'
}
