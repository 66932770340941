import { OrganizationType } from 'lib/enums';
import { getModelFromSnapshot } from 'lib/model';
import { OrganizationModel } from 'lib/model/objects/organizationModel';
import { exists } from 'lib/types';
import { selectActiveOrganization, selectUser } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import { getFirebaseContext } from 'utils/firebase';
import GovernmentInfo from './GovernmentInfo';
import HoldingCompanyInfo from './HoldingCompanyInfo';
import LawFirmInfo from './LawFirmInfo';
import OtherOrganizationInfo from './OtherOrganizationInfo';
import PublisherInfo from '../publisher/publisherSettings';

export function GeneralInfo() {
  const activeOrganization = useAppSelector(selectActiveOrganization);
  const user = useAppSelector(selectUser);

  if (!exists(activeOrganization) || !exists(user)) {
    return null;
  }

  const organization = getModelFromSnapshot(
    OrganizationModel,
    getFirebaseContext(),
    activeOrganization
  );

  switch (organization.modelData.organizationType) {
    case OrganizationType.government.value:
      return <GovernmentInfo activeOrganization={organization} />;
    case OrganizationType.holding_company.value:
      return <HoldingCompanyInfo activeOrganization={organization} />;
    case OrganizationType.law_firm.value:
      return <LawFirmInfo activeOrganization={organization} />;
    case OrganizationType.newspaper.value:
      return <PublisherInfo activeOrganization={organization} user={user} />;
    case OrganizationType.funeral_home.value:
    case OrganizationType.other_organization.value:
      return <OtherOrganizationInfo activeOrganization={organization} />;
    default:
      return null;
  }
}
