/* eslint-disable no-use-before-define */
import { MadlibDataType } from './madlib';
import { EOrganization } from './organization';
import { ETemplate } from './templates';
import { EUser } from './user';
import { ERef } from './firebase';
import { ERate } from './rates';
import { BulkInvoice, EInvoice, EInvoiceRecipient } from './invoices';
import { ETransfer } from './transfers';
import ModularSize from './modularSize';
import {
  AffidavitTemplate,
  EPublicNotice,
  ESnapshotExists,
  FirebaseTimestamp
} from '.';
import { NoticeByMail } from './noticeByMail';
import { InvoiceMail } from './customer';
import { LabelType } from './publicNotice';
import { EPayout } from './payout';
import { BuildSyncData, SyncData } from './integrations/sync';
import { FileType } from './mime';
import { EData } from './data';
import { Event, EventType } from './events';
import { DBPricingObj } from '../pricing';
import { ConfirmationStatus } from '../enums';
import { NoticeAAC } from './affidavits';

export type IDDocumentFormat = 'rtf' | 'jpg' | 'idml' | 'pdf';

export type IDRequest = {
  id: string;
  noticeId: string;
  downloadUrl?: string;
  html?: string;
  quality?: string;
  columns: number;
  resizeTextFramesForProofPDF?: boolean;
  outputToBase64?: boolean;

  /**
   * Document formats to generate. If only one is specified,
   * the file is returned alone. If multiple are specified they
   * are returned together as a ZIP.
   */
  formats?: IDDocumentFormat[];

  dynamicHeader?: string | null;
  dynamicFooter?: string | null;
  linerBorder?: boolean;
  icmlSubstitutions?: Record<string, string>;
  template?: string;
  fullPageTemplate?: string;
  delimeter?: string;
  notices?: { body: string; header: string | undefined | null }[];
  linerMaxColumns?: number | null;
  optimizeColumns?: boolean;
  // True if the template contains a custom header style (TopHeader) and
  // enabled to use that custom style
  customHeader?: boolean;

  // Enables us to split the content of the notice into multiple columns
  textColumnCount?: number;
};

export type DisplayParams = {
  words?: number;
  lines?: number;
  area: number;
  height: number;
  width: number;
  columns: number;
  imgs?: Array<string>;
  headerWords?: number;
  headerLines?: number;
  boldWords?: number;
  nonTableBoldedLines?: number;
  justifications?: {
    LEFT_ALIGN: {
      lines: number;
      words: number;
    };
    RIGHT_ALIGN: {
      lines: number;
      words: number;
    };
    CENTER_ALIGN: {
      lines: number;
      words: number;
    };
    LEFT_JUSTIFIED: {
      lines: number;
      words: number;
    };
  };
  isTableOverflow?: boolean;
  areAnyTablesTooWide?: boolean;
  maxHeightExceeded?: boolean;

  /** Information about images present in the notice */
  images?: {
    /** Height in inches */
    height: number;

    /** Width in inches */
    width: number;
  }[];
};

export type Crop = {
  height: number;
  width: number;
  absHeight: number;
  absWidth: number;
  x: number;
  y: number;
  aspect: number;
};

export type ColumnRepAction = {
  type: EventType;
  event?: ERef<Event>;
};

export type ENotice = EData & {
  user: ERef<EUser>;
  confirmed?: boolean;

  confirmedReceipt?: boolean;
  /**
   * Indicates whether a notice has been confirmed by the publisher, needs more
   * work before the publisher can confirm it, or hasn't been checked by the
   * publisher yet. */
  confirmationStatus?: ConfirmationStatus;

  billingStatus: number;
  publicationDates: FirebaseTimestamp[];
  affidavitSubmitted?: boolean;

  /**
   * The Firebase storage path to the completed, uploaded affidavit
   */
  affidavit?: string;

  /**
   * Timestamp of first affidavit uploaded to the notice
   */
  affidavitFirstUploadedAt?: FirebaseTimestamp;

  /**
   * Timestamp of most recent completed affidavit to be uploaded to the notice
   */
  affidavitLastUploadedAt?: FirebaseTimestamp;

  /**
   * This is the URL for the completed affidavit
   * that is availablefor the advertiser to download.
   * (Distinct from generatedAffidavitURL)
   */
  affidavitURL?: string;

  /**
   * Normal affidavits are generated by Column based on the affidavit that is uploaded to
   * a notice. Normalized affidavits:
   * 1. Are in A4 page format
   * 2. Are rotated so the text is in the right direction
   * These affidavits are then used by Lob to send outbound mail to the customer.
   */
  normalAffidavit?: string | null;

  /**
   * See ONCALL-3605: this allows us to start the affidavit text on the second page even if
   * the settings would normally allow the first image to fit on the first page
   */
  forceNoticeTextToSecondAffidavitPage?: boolean;

  /*
   *  The advertiser for this notice
   */
  filer: ERef<EUser>;

  /**
   * @deprecated Do not use this field in new code. Instead use the 'filer'
   * field to access the notice filer.
   */
  userId: string;

  newspaper: ERef<EOrganization>;
  noticeType: number;
  previousNoticeType: number | null;
  /**
   * noticeStatus is only set upon notice submission
   */
  noticeStatus: number;
  /**
   * This text added in notice when a Typeform notice placed. See ingestNotice in zapier.ts
   */
  text?: string;

  /**
   * The date the notice was promoted from 'draft' status
   * This is NOT a timestamp of when the notice was confirmed by the publisher! For that, see
   * the `confirmedReceiptTime` field.
   */
  confirmedAt: FirebaseTimestamp;

  /**
   * The user who confirmed the notice
   * If the user is a publisher or admin and edits the notice, this field is updated
   * If you need to find the first user who confirmed, refer to the NOTICE_CONFIRMED event
   */
  confirmedBy?: ERef<EUser> | null;
  editedAt?: FirebaseTimestamp;
  lastEditedBy?: ERef<EUser>;
  affidavitUploadedBy?: ERef<EUser>;
  squashable?: boolean;
  dynamicHeaders: string[] | null;
  adTemplate: ERef<ETemplate>;
  /**
   * NOTE: dispite this misleading name, this is actually a storage path, not a URL!
   */
  displayUrl?: string;
  display?: string;
  vision?: {
    avgWordSize: number;
  };

  /**
   * The advertiser organization that placed the notice.
   */
  filedBy?: ERef<EOrganization>;

  /**
   * The user who actually uploaded the noticee - potentially on behalf of an advertiser
   */
  createdBy?: ERef<EUser>;

  /**
   * @deprecated.
   */
  columnRepActions?: ColumnRepAction[];

  proofStoragePath: string | null;

  /**
   * The associated Transfer object, or 'true' if the transfer has occurred but
   * via another method (bulk invoice, payout, etc.).
   *
   * A Transfer reference can represent two distinct (and somewhat opposite) flows:
   *   1. For a notice paid inside Column/Stripe the transfer is the movement of the pretotal
   *      amount from our Stripe Connect balance into the publisher's Stripe Connect balance.
   *   2. For a notice paid outside Column/Stripe (see: invoice.invoiceOutsideColumn or
   *      invoice.paid_outside_stripe) the transfer is the movement of the Column fee from the
   *      publisher to us.
   */
  transfer?: ERef<ETransfer> | boolean | null;

  /**
   * DWOLLA: If this notice is included in a bulk payout, transfer will be true and this field
   * will contain a reference to the payout object.
   *
   * STRIPE (default): Contains ref to the payout for this notice, if any.
   */
  payout?: ERef<EPayout>;

  icon?: string;
  jpgStoragePath?: string | null;
  jpgURL?: string | null;
  displayParams?: DisplayParams;
  invoice: ERef<EInvoice> | null;
  finalProofURL: string | null;
  confirmedHtml: string | null;
  unusedConfirmedHtml?: string;
  columns: number;
  modularSize?: ERef<ModularSize>;
  confirmedCrop?: Required<Crop>;
  rate: ERef<ERate>;
  processedDisplay?: boolean;
  pdfStoragePath?: string;
  /**
   * When running validation on a single notice, we use OCR on the PDF to
   * extract the text. To avoid extraneous OCR calls, we cache the OCR response
   * in storage and reference it here.
   *
   * Note: This field is only updated when running notice validation, so it's
   * possible that the OCR response is out of date. Before using it as a source
   * of truth on the notice content, you should check the file name here matches
   * the file name in the PDF storage path (swapping the `.pdf` extension for a
   * `.json` extension).
   */
  ocrStoragePath?: string;
  rtfStoragePath?: string;
  idmlStoragePath?: string;
  generatedAffidavitStoragePath: string | null;

  /**
   * This is the URL that we generate for the
   * publisher to download and sign & notarize
   * before re-uploading for the advertiser.
   * (Distinct from affidavitURL)
   */
  generatedAffidavitURL: string | null;
  proofURL?: string | null;
  drafts?: ERef<ENoticeDraft>[] | null;
  pricing?: DBPricingObj | null;
  amount?: number;

  /** Order number in the publisher's system */
  customId?: string | null;

  pdfURL?: string;
  idmlURL?: string;
  rtfURL?: string;
  dynamicFooter?: string;
  footerFormatString?: string;

  /**
   * Reason for the notice being 'force' archived, i.e. not archived by the system after transfer.
   * If truthy, ensures the notice stays archived when its updated
   */
  forceArchiveReason?: string | null;
  isArchived?: boolean;
  createTime?: FirebaseTimestamp;
  /**
   * @deprecated Historically, we have used this field to link a notice to a
   * custom affidavit template, by saving a storage path. This is now deprecated
   * in favor of the `defaultAffidavitTemplate` field.
   */
  customAffidavit?: string;
  /**
   * The affidavit template we want to use for this notice.
   */
  defaultAffidavitTemplate?: ERef<AffidavitTemplate>;
  defaultRateOverride?: boolean;
  referenceId?: string;

  /**
   * The timestamp of when the notice was confirmed by the publisher
   * (not to be confused with the `confirmedAt` field, which is the timestamp
   * of when the notice was promoted from 'draft' status)
   */
  confirmedReceiptTime?: FirebaseTimestamp | null;
  formattingError?: string;
  postWithoutFormatting?: boolean;
  requiresFormatting?: boolean;
  continueWithLargeFile?: boolean;
  confirmPostWithoutFormatting?: boolean;
  /**
   * This property is used in the postWithoutFormatting flow to
   * 1. Allow the advertiser to provide formatting descriptions to publishers for notices that are submitted without formatting
   * 2. Allow publishers to submit programming comments to external ad builders like AffinityX
   */
  designNotes?: {
    message: string;
    lastEditedAt: FirebaseTimestamp;
    lastEditedBy?: ERef<EUser>;
  };

  /**
   * Fixed price in cents
   * Generally comes in from zaps and overrides the rate and additional fee information for a notice
   */
  fixedPrice?: number;

  noticeCancellationReason?: string;
  cancelledBy?: ERef<EUser>;
  requireUpfrontPayment?: boolean | null;
  bulkExportAt?: FirebaseTimestamp;
  isWithinBulkInvoice?: boolean;

  /**
   * (If applicable) set once the bulk invoice containing this notice has
   * been generated.
   */
  bulkInvoice_v2?: ERef<BulkInvoice> | null;

  bulkInvoice?: ERef<BulkInvoice> | null;

  bulkInvoiceByPubDate?: boolean;
  invoiceMailings?: InvoiceMail[];
  testNotice?: boolean;

  /**
   * When set (during the advertiser filing flow) invoices should be sent to
   * this recipient and NOT to the filer.
   */
  invoiceRecipient?: EInvoiceRecipient;

  requireEmailAffidavit?: boolean;
  mailAffidavitsOutsideColumn?: boolean;

  /**
   * When notices fail to sync via an integration, we set this property to true;
   * and when they succeed we set it to false.
   *
   * This represents the status of the LAST sync. For notices that sync multiple
   * times in their lifecycle, this may not be that relevant.
   */
  failedSync?: boolean;

  /**
   * @deprecated see syncData
   */
  failedSyncBeforeDeadline?: boolean;

  sdInstanceId?: string | null;
  sdAssetId?: string | null;
  sdHeaderAssetId?: string | null;
  classifiedType?: LabelType;

  failedIndexing?: boolean;

  /**
   * Tracks experimental data associated with the notice. On the notice
   * detail screen the information in this object will be added as URL params,
   * allowing us to run targeted pendo guides based on this information
   */
  experimentData?: Record<string, string>;

  /**
   * See: helpers.getNoticeIsInvoicedOutsideColumn
   * @deprecated
   */
  invoicedOutsideColumn?: boolean;
  mostRecentIntegrationTaskPath?: string | null;

  /**
   * Affidavit reconciliation settings can be overriden at the notice
   * level. We also store information on notarization for that particular
   * notice
   */
  affidavitReconciliationSettings?: Partial<
    EOrganization['affidavitReconciliationSettings']
  > | null;

  /**
   * The notice's affidavit configuration, which is a combination of the
   * publisher's default configuration and any overrides set at the notice
   * type or customer level. These settings are saved upon invoicing.
   * This object also contains notice-specific fields, like notarization.
   */
  automatedAffidavitConfiguration?: NoticeAAC;

  /**
   *  if publisher turns off requireUpfrontPayment for notice in the UI, this field is true
   */
  requireUpfrontPaymentModifiedByPublisher?: boolean;

  /**
   * @deprecated this information is now contained in syncData.syncCustomerId
   */
  internalID?: string;

  /**
   * Notices that have been tagged with Biddy IDs. Documentation on
   * these notices from the R&D team is available here: https://www.notion.so/column/Biddy-Column-Partnership-Plan-486b87c065f14dbc9686b32536e09e9e
   */
  biddyID?: number;

  /**
   * Notices that have an associated notice by mail requirement. Documentation
   * on these notices from the R&D team is available here: https://www.notion.so/Notice-by-Mail-Spec-1095ab6ce39d432a8c13ae96472cba99
   */
  noticeByMail?: NoticeByMail;

  /**
   * Saved data from the latest sync.
   * Saved data from the latest sync (or to be used in the next sync).
   */
  syncData?: SyncData;

  /**
   * Saved data from the latest build sync.
   */
  buildSyncData?: BuildSyncData;

  /**
   * @deprecated this field was introduced for Gannett and never used in production.
   *
   * If true, when the payment for this notice is transferred to the publisher it should
   * include the Column convenience fee (and any other fees collected by Column). We will
   * recover those fees from the publisher later through payColumnInvoices or a similar flow.
   */
  transferWithFees?: boolean;
  /**
   * This field is used for indexing drafts in the notices table (as draftownerid)
   * and for determining the original user who placed the notice
   */
  owner?: ERef<EUser> | null;
  /**
   * Array containing references to publicNotices generated from userNotice
   */
  publicNotices?: ERef<EPublicNotice>[];

  /**
   * Contains the template data of the Madlib editor
   */
  madlibData?: MadlibDataType | null;

  /**
   * For publishers using specific display ad sizing, this property contains keys which we use in the cache management system for each publisher
   * ex. eighthPage, quarterPageHorizontal, quarterPageVertical, halfPageHorizontal
   */
  displayAdSize?: number;

  /**
   * Contains header text that shows at the top of the notice preview under the dymanic header (if any).
   * Only available in notices placed by the newspaper's template that supports customised header styling
   */
  headerText?: string;

  /**
   * This field points to an anonymous FIREBASE AUTH user up until the point
   * where notice is updated with a filer, which happens one of two ways:
   * 1. The user logs in mid-placement, and the userId/filer are updated to the logged in user
   * 2. The user creates and logs in as an anonymous (firestore) user once they enter
   *    information in ConfirmFilerStep and the userId/filer are updated to the logged in user
   *
   * This field is only temporarily used in anonymous (logged out) placement
   * to allow access to notices/drafts for a user in an anonymous session
   *
   * For all other instances of accessing the user who placed a notice, we refer to
   * the filer field which references an existing firestore user
   *
   * In both of the above cases, once a user logs in this field is nulled on the draft
   * and does not persist to the confirmed notice
   *
   * See https://firebase.google.com/docs/auth/web/anonymous-auth for more
   * information on firebase anonymous auth.
   *
   * See the property isAnonymous on user.ts for more information on our internal
   * definition of an anonymous Column user.
   */
  anonymousFilerId?: string;

  /**
   * `true` if the notice was placed using the inbox automation service
   */
  placedViaEmailAutomation?: boolean;
};

export type ENoticeDraft = Omit<ENotice, 'drafts'> & {
  owner: ERef<EUser> | null;
  inactive?: boolean;
  original: ERef<ENotice> | null;
  unusedDisplay: string;
  publicationDatesUpdated?: boolean;
};

export interface EPreviewNotice
  extends Pick<
    ENoticeDraft,
    | 'publicationDates'
    | 'newspaper'
    | 'rate'
    | 'confirmedHtml'
    | 'dynamicHeaders'
  > {
  // This field is optional here but not on ENoticeDraft
  adTemplate?: ENoticeDraft['adTemplate'];

  // Note: these two fields are actually never used, they are just needed
  // so that this object can be fed into notice type helpers.
  noticeType?: number;
  previousNoticeType?: number;
}

export type AffidavitDisabledNotice = Pick<
  ENotice,
  'noticeStatus' | 'noticeType' | 'publicationDates'
>;

/**
 * FINALIZED_DISPLAY_AD is a single file that represents the entirety of a display notice that is to be published
 *
 * DISPLAY_AD_COMPONENT is a file that constitutes part of a display notice that must be further built before being published
 *
 * SUPPLEMENTAL_UPLOAD is a file, typically uploaded via Zapier, that is not part of the notice content itself, but rather used to verify the information in or the legal compliance of a notice
 *
 * TEXT_FILE is a file, e.g. a Word file, RTF, or plain text file, that is uploaded and used as the notice content for a *liner* ad
 *
 *
 * A file that is originally classified as a FINALIZED_DISPLAY_AD or a TEXT_FILE will be reclassified as a DISPLAY_AD_COMPONENT if multiple files are uploaded or if the file is otherwise submitted without formatting.
 *
 * When we receive a response from a build integration (AffinityX), we will set the built ad as a FINALIZED_DISPLAY_AD, which will then replace the existing files upon the next notice edit.
 */
export enum NoticeFileTypes {
  finalized_display_ad = 'finalized display ad',
  display_ad_component = 'display ad component',
  supplemental_upload = 'supplemental upload',
  text_file = 'text file'
}

/**
 * This collection will exist as a subcollection on an ENotice or ENoticeDraft
 * Initially, this subcollection will just be used to replace the `attchedFiles` property for display ads and liner ads created from uploaded files
 * However, it may be expanded later to include all files (affidavits, invoices, proofs, etc.) that are part
 * of a notice's lifecycle
 */
export type ENoticeFile = {
  type: NoticeFileTypes;

  /**
   * This property will appear on a file if it was migrated to this subcollection from the old `attachedFiles` property,
   * which did not have as clearly defined a schema.
   */
  legacySchema?: true;

  /**
   * The path to the OPERATIVE version of the file in Firebase storage, meaning the FORMATTED version if the file
   * has been converted/formatted, or the UNFORMATTED version if it has not
   * (format example: 'notice_exports/cbec.-0011577547.jpg')
   */
  firebaseStoragePath?: string;

  /**
   * The path to the UNFORMATTED version of the file in Firebase storage
   * If the file has not been formatted/converted, then this property will be the same as `firebaseStoragePath`
   * (format example: 'notice_exports/cbec.-0011577547.jpg')
   */
  originalFirebaseStoragePath?: string;

  /**
   * A URL to the UNFORMATTED version of a file hosted in Firebase, Imgix, Cloudinary, or some other third party
   */
  linkToUploadedFile?: string;

  /**
   * If applicable, a URL to the FORMATTED version of the file hosted in Firebase, Imgix, Cloudinary, or some other third party
   */
  linkToFormattedFile?: string;

  /**
   * The name of the file when it was uploaded
   * For arbitrary legacy reasons, this fields *does not include* the file extension
   */
  originalFileName?: string;

  /**
   * The modified file name for internal use
   * For arbitrary legacy reasons, this field *includes* the file extension
   */
  sanitizedFileName?: string;

  /**
   * A link to the file where it was first uploaded (usually Zapier or Typeform)
   * TODO (APP-161): deprecate this field when we move away from Type-Zaps?
   */
  linkToOriginalFile?: string;

  /**
   * The file extension provided by the file name (e.g., 'pdf', 'jpg', 'idml', etc.)
   * If the file name does not have such an extension, this property will be `null`
   */
  fileFormat?: string | null;

  /**
   * The file type as an enum
   * First we attempt to determine the file type based on the file name extension;
   * If this is unsuccessful, we attempt to make the determination based on the MIME type;
   * If neither of these methods is successful, we store this as `null`
   */
  fileType?: FileType | null;

  /**
   * Our previous `attachedFiles` property had a very irregular gamut of structures & properties across our database
   * This map will contain all of those miscellaneous data so that we don't lose any information
   * For more information on the data contained herein, see functions/src/migrations/migration_cache_data/1676388910855_attachedFiles_data_types.ts
   */
  oldSchemaMetadata?: Record<string, string>;

  /**
   * Timestamp for when we receive a response file from AffinityX
   * (helps us in cases where we have multiple response files
   * for a single notice).
   */
  affinityXDeliveryTime?: FirebaseTimestamp;
};

export type ENoticeFileNoticeContent = ENoticeFile & {
  type:
    | NoticeFileTypes.display_ad_component
    | NoticeFileTypes.finalized_display_ad
    | NoticeFileTypes.text_file;
  firebaseStoragePath?: string;
  originalFirebaseStoragePath: string;
  linkToUploadedFile: string;
  originalFileName: string;
  sanitizedFileName: string;
  fileFormat: string | null;
  fileType: FileType | null;
};

export type ENoticeFileSupplementalContent = ENoticeFile & {
  type: NoticeFileTypes.supplemental_upload;
  firebaseStoragePath: string;
  linkToUploadedFile: string;
  linkToOriginalFile: string;
};

export type ENoticeFileLegacySchema = ENoticeFile & {
  legacySchema: true;
  oldSchemaMetadata: Record<string, string>;
};

export const isLegacySchemaData = (
  noticeFile: ENoticeFile
): noticeFile is ENoticeFileLegacySchema => {
  return !!noticeFile.legacySchema;
};

export const isLegacySchema = (
  noticeFileSnap: ESnapshotExists<ENoticeFile>
): noticeFileSnap is ESnapshotExists<ENoticeFileLegacySchema> => {
  return isLegacySchemaData(noticeFileSnap.data());
};

export const isNoticeContentData = (
  noticeFile: ENoticeFile | undefined
): noticeFile is ENoticeFileNoticeContent => {
  if (!noticeFile) return false;
  const isValidType = [
    NoticeFileTypes.display_ad_component,
    NoticeFileTypes.finalized_display_ad,
    NoticeFileTypes.text_file
  ].includes(noticeFile.type);
  const isNotLegacy = !isLegacySchemaData(noticeFile);
  return isValidType && isNotLegacy;
};

export const isNoticeContent = (
  noticeFileSnap: ESnapshotExists<ENoticeFile>
): noticeFileSnap is ESnapshotExists<ENoticeFileNoticeContent> => {
  return isNoticeContentData(noticeFileSnap.data());
};

export const isSupplementalUploadData = (
  noticeFile: ENoticeFile
): noticeFile is ENoticeFileSupplementalContent => {
  const isValidType = noticeFile.type === NoticeFileTypes.supplemental_upload;
  const isNotLegacy = !isLegacySchemaData(noticeFile);
  return isValidType && isNotLegacy;
};

export const isSupplementalUpload = (
  noticeFileSnap: ESnapshotExists<ENoticeFile>
): noticeFileSnap is ESnapshotExists<ENoticeFileSupplementalContent> => {
  return isSupplementalUploadData(noticeFileSnap.data());
};
