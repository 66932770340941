import { useFetchAvailablePublishers } from 'hooks/useFetchAvailablePublishers';
import { Autocomplete } from 'lib/components/Autocomplete';
import { Product } from 'lib/enums';
import { useState, useContext, useEffect, useMemo } from 'react';
import { PublisherLocationFilter } from 'routes/placeScroll/ConfirmPublisher/PublisherLocationFilter';
import { getFirebaseContext } from 'utils/firebase';
import { NewspaperOrder, NewspaperOrderStatus } from 'lib/types/newspaperOrder';
import { PublishingMedium } from 'lib/enums/PublishingMedium';
import { PRODUCT_TO_NAME } from 'lib/enums/Product';
import { getModelFromSnapshot } from 'lib/model';
import { getOrThrow } from 'lib/utils/refs';
import { OrganizationModel } from 'lib/model/objects/organizationModel';

import { OrderModel } from 'lib/model/objects/orderModel';
import { FilingTypeModel } from 'lib/model/objects/filingTypeModel';
import { useFetchSubdomainAssociatedPapers } from 'hooks/useFetchSubdomainAssociatedPapers';
import { Ad, CategoryChoiceOption } from 'lib/types/ad';
import { isDefined } from 'lib/helpers';
import { useAppSelector } from 'redux/hooks';
import { selectIsPublisher } from 'redux/auth';
import { getContextKey } from 'sagas/AuthSaga';
import { EOrganization, ERef, ESnapshotExists, ETemplate } from 'lib/types';
import { uniqBy } from 'lodash';
import { ResponseOrError, wrapSuccess } from 'lib/types/responses';
import { useBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import LoadingState from 'components/LoadingState';
import MultiStepHeader from '../../components/MultiStepHeader';
import { NewspapersContext } from '../../contexts/NewspapersContext';
import { NewspaperOrdersFormData } from '../../PlacementFlowStepSelector';
import PublisherCard from './PublisherCard';
import PublisherMadlibQuestions, {
  getMadlibConfigForPublisherCard
} from './PublisherMadlibQuestions';
import { shouldForceGrayscale } from '../colorHelpers';
import { getDefaultLayout } from '../layoutHelpers';

async function getNewspaperOrder(
  newspaper: OrganizationModel,
  publishingMedium: PublishingMedium,
  inputData: Partial<Ad>,
  productFilingType: FilingTypeModel,
  adTemplate: ERef<ETemplate>
): Promise<ResponseOrError<Partial<NewspaperOrder>>> {
  const grayscaleResult = await shouldForceGrayscale(productFilingType);

  if (grayscaleResult.error) {
    return grayscaleResult;
  }

  const supportedLayouts =
    productFilingType.getSortedSupportedLayouts(inputData);

  return wrapSuccess({
    newspaper: newspaper.ref,
    adTemplate: productFilingType?.modelData.template || adTemplate,
    publishingDates: [],
    status: NewspaperOrderStatus.DRAFT,
    publishingMedium,
    colorOptions: {
      isGrayscale: grayscaleResult.response,
      backgroundColor: 'transparent',
      borderColor: 'transparent'
    },
    // check if productFilingType exists before adding it to the order so we don't have an undefined field in firestore
    ...(productFilingType
      ? {
          filingType: productFilingType.ref,
          layout: getDefaultLayout(supportedLayouts)
        }
      : {})
  });
}

const getShouldShowPublications = ({
  relatedPublisherOptions,
  isPublisher,
  subdomain
}: {
  relatedPublisherOptions: { label: string; value: string }[];
  isPublisher: boolean;
  subdomain: string | null;
}) => {
  // for publishers
  if (isPublisher) {
    // hide if there are no options besides the current
    return {
      showAddPublications: relatedPublisherOptions.length > 1,
      useRelatedPublishers: true
    };
  }

  // for advertisers

  // hide if you are on a subdomain and there are no other options
  return {
    showAddPublications: !subdomain || relatedPublisherOptions.length > 1,
    useRelatedPublishers: false
  };
};

const getTitleAndDescriptionForAddPublisherSetup = ({
  showAddPublications,
  isPublisher,
  productTypeName
}: {
  showAddPublications: boolean;
  isPublisher: boolean;
  productTypeName: string;
}) => {
  if (isPublisher) {
    if (showAddPublications) {
      return {
        title: `Where would you like to publish your ${productTypeName}?`,
        description:
          'Choose additional publications and publishing methods so this order will get more reach.'
      };
    }
    return {
      title: `How would you like to place your order?`,
      description:
        'This selection controls how your ad will be sent to pagination.'
    };
  }

  if (showAddPublications) {
    return {
      title: `Where would you like to publish your ${productTypeName}?`,
      description: "Choose the newspapers that you'd like to publish with."
    };
  }
  return {
    title: `How would you like to place your order?`,
    description:
      'This selection controls how your ad will be sent to pagination.'
  };
};

type SelectPublicationProps = {
  newspaperOrdersFormData: NewspaperOrdersFormData;
  onNewspaperOrdersFormDataChange: React.Dispatch<
    React.SetStateAction<NewspaperOrdersFormData>
  >;
  product: Product;
  inputData: Partial<Ad>;
  orderModel: OrderModel;
  onUpdateAd: (update: Partial<Ad>) => void;
};

function SelectPublication({
  newspaperOrdersFormData,
  onNewspaperOrdersFormDataChange,
  product,
  inputData,
  onUpdateAd
}: SelectPublicationProps) {
  const productTypeName = PRODUCT_TO_NAME[product].singular.toLowerCase();
  const isPublisher = useAppSelector(selectIsPublisher);

  const completeMadlibsInPublicationSelectionStep = useBooleanFlag(
    LaunchDarklyFlags.SHIFT_ORDER_MADLIB_QUESTIONS_TO_FILING_TYPE_SELECTION
  );

  const context = getFirebaseContext();

  const organizations = context.organizationsRef();

  const {
    publishersAvailableForPlacement,
    filingTypeByPublisherAndPublishingMedium
  } = useContext(NewspapersContext);

  const [selectedPublishers, setSelectedPublishers] = useState(
    uniqBy(
      publishersAvailableForPlacement.filter(newspaper =>
        newspaperOrdersFormData.some(o => o.newspaper?.id === newspaper.id)
      ),
      'id'
    )
  );

  const [searchedNewspaperId, setSearchedNewspaperId] = useState<
    string | undefined
  >(undefined);

  const searchedNewspaperLoaded = publishersAvailableForPlacement.find(
    o => o.id === searchedNewspaperId
  );
  const {
    loading: availablePublishersLoading,
    stateOptions,
    stateFilter,
    setStateFilter,
    publisherOptions
  } = useFetchAvailablePublishers({
    restrictedSingleState: undefined,
    isUserPublisher: false,
    restrictedPublisherIds: [],
    product
  });

  const {
    loading: relatedPublishersLoading,
    relatedPublisherOptions,
    autoSelectedPaper
  } = useFetchSubdomainAssociatedPapers(product, stateFilter);

  const loading = relatedPublishersLoading || availablePublishersLoading;

  async function removeNewspaper(newspaperId: string) {
    onNewspaperOrdersFormDataChange(
      newspaperOrdersFormData.filter(no => no.newspaper?.id !== newspaperId)
    );
    setSelectedPublishers(existingPublishers =>
      existingPublishers.filter(p => p.id !== newspaperId)
    );
    setSearchedNewspaperId(undefined);
  }

  const addMediumToOrder = async (
    newspaper: ESnapshotExists<EOrganization>,
    medium: PublishingMedium
  ) => {
    const relevantPublishingData =
      filingTypeByPublisherAndPublishingMedium[newspaper.id]?.[
        inputData.filingTypeName as CategoryChoiceOption
      ]?.[medium];
    if (!relevantPublishingData) return;
    const relevantFilingType = relevantPublishingData.filingType;
    const { adTemplate } = relevantPublishingData;
    const newOrderResult = await getNewspaperOrder(
      getModelFromSnapshot(OrganizationModel, getFirebaseContext(), newspaper),
      medium,
      inputData,
      relevantFilingType,
      adTemplate
    );
    if (newOrderResult.error) {
      return newOrderResult;
    }
    onNewspaperOrdersFormDataChange([
      ...newspaperOrdersFormData,
      newOrderResult.response
    ]);
  };

  async function addNewspaper(newspaperId: string) {
    const publisherSnapshot = await getOrThrow(organizations.doc(newspaperId));
    setSelectedPublishers(existingPublishers => [
      ...existingPublishers,
      publisherSnapshot
    ]);
    setSearchedNewspaperId(newspaperId);
    const validMediums =
      filingTypeByPublisherAndPublishingMedium[newspaperId]?.[
        inputData.filingTypeName as CategoryChoiceOption
      ];
    const firstValidMedium = Object.keys(
      validMediums || {}
    )[0] as PublishingMedium;
    await addMediumToOrder(publisherSnapshot, firstValidMedium);
  }

  useEffect(() => {
    if (newspaperOrdersFormData.length || !autoSelectedPaper) {
      return;
    }
    // Auto selected paper is either the publisher's active organization or the paper associated with the custom subdomain
    if (autoSelectedPaper) {
      void addNewspaper(autoSelectedPaper.value);
    }
  }, [autoSelectedPaper?.value]);

  const allFormattedPublisherOptions = publisherOptions.map(o => ({
    label: o.name,
    value: o.id
  }));

  const { showAddPublications, useRelatedPublishers } =
    getShouldShowPublications({
      subdomain: getContextKey(),
      isPublisher,
      relatedPublisherOptions
    });

  const selectedNewspaperIds = selectedPublishers.map(p => p.id);

  const { title, description } = getTitleAndDescriptionForAddPublisherSetup({
    showAddPublications,
    isPublisher,
    productTypeName
  });

  const { madlibError, madlibFilingType } = useMemo(() => {
    return getMadlibConfigForPublisherCard(
      filingTypeByPublisherAndPublishingMedium,
      publishersAvailableForPlacement,
      inputData,
      newspaperOrdersFormData
    );
  }, [newspaperOrdersFormData.map(o => o.filingType?.id).join(',')]);

  return (
    <>
      <MultiStepHeader title={title} description={description} />
      <div className="grid grid-cols-12 gap-6">
        {showAddPublications && (
          <>
            <div className="col-span-12 md:col-span-6 xl:col-span-8">
              <Autocomplete
                id="selectPublisher"
                labelText="Add publications"
                placeholder={`Choose one or more places to run your ${productTypeName}`}
                value={
                  searchedNewspaperLoaded && !loading ? searchedNewspaperId : ''
                }
                options={
                  useRelatedPublishers
                    ? relatedPublisherOptions
                    : allFormattedPublisherOptions
                }
                onChange={newspaperId => {
                  if (
                    newspaperOrdersFormData.find(
                      o => o.newspaper?.id === newspaperId
                    )
                  ) {
                    void removeNewspaper(newspaperId);
                  } else if (newspaperId) {
                    void addNewspaper(newspaperId);
                  }
                }}
                loading={loading}
                required={publishersAvailableForPlacement.length === 0}
                validationMessages={{
                  valueMissing: 'Please select a publisher'
                }}
                selectedOptionsValues={selectedNewspaperIds}
                showCheckBoxForSelectedItems
              />
            </div>
            <div className="col-span-12 md:col-span-6 xl:col-span-4 pt-8">
              <PublisherLocationFilter
                stateOptions={stateOptions}
                onStateChange={state => {
                  setStateFilter(state);
                }}
                activeFilters={{ stateFilter }}
              />
            </div>
          </>
        )}

        {selectedPublishers.length === 0 && (
          <div className="col-span-12 flex items-center justify-center">
            <LoadingState isFullScreen={false} />
            <input required className="hidden" />
          </div>
        )}

        <div className="col-span-12 flex flex-col gap-8">
          {selectedPublishers.map(newspaper => {
            const ordersForNewspaper = newspaperOrdersFormData.filter(
              o => o.newspaper?.id === newspaper.id
            );
            const publisherDataForFilingTypes =
              filingTypeByPublisherAndPublishingMedium[newspaper.id]?.[
                inputData.filingTypeName as CategoryChoiceOption
              ];
            const validMediums = Object.keys(
              publisherDataForFilingTypes || {}
            ) as PublishingMedium[];
            const publisherProductPublishingSettings = validMediums
              .map(
                medium =>
                  publisherDataForFilingTypes?.[medium]
                    ?.productPublishingSetting
              )
              .filter(isDefined);

            return (
              <div key={newspaper.id} className="col-span-12">
                <PublisherCard
                  inputData={inputData}
                  onRemovePublisher={() => {
                    void removeNewspaper(newspaper.id);
                  }}
                  required={
                    loading ||
                    newspaper.data().name === autoSelectedPaper?.label
                  }
                  newspaper={newspaper}
                  validProductPublishingSettings={publisherProductPublishingSettings.map(
                    pps => pps.modelData
                  )}
                  publishingMediums={ordersForNewspaper
                    .map(o => o.publishingMedium)
                    .filter(isDefined)}
                  onPublishingMediumsChange={async value => {
                    const missingMedium = !ordersForNewspaper.some(
                      o => o.publishingMedium === value
                    );
                    if (missingMedium) {
                      await addMediumToOrder(newspaper, value);
                    } else {
                      onNewspaperOrdersFormDataChange(
                        newspaperOrdersFormData.filter(
                          o =>
                            o.newspaper?.id !== newspaper.id ||
                            o.publishingMedium !== value
                        )
                      );
                    }
                  }}
                />
              </div>
            );
          })}
        </div>
        {madlibFilingType && completeMadlibsInPublicationSelectionStep && (
          <PublisherMadlibQuestions
            madlibFilingType={madlibFilingType}
            madlibError={madlibError}
            onUpdateAd={onUpdateAd}
            inputData={inputData}
          />
        )}
      </div>
    </>
  );
}

export default SelectPublication;
