import { TextField } from 'lib/components/TextField';
import { ERate } from 'lib/types';
import { useState } from 'react';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import LabeledSwitch from 'lib/components/LabeledSwitch';
import { valueIsNumber } from 'lib/pricing';
import { FeeSplit } from 'lib/types/feeSplit';
import CurrencyTextField from 'lib/components/CurrencyTextField';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { useBooleanFlag } from 'utils/flags';

type AdminSettings = Pick<
  ERate,
  'enotice_fee_pct' | 'convenienceFeeSplit' | 'convenienceFeeFlat'
>;
type AdminSettingsCardProps = {
  onChange: (arg: AdminSettings) => void;
  value: AdminSettings;
  isEditingNoticeRate: boolean;
};

export default function AdminSettingsCard({
  onChange,
  value,
  isEditingNoticeRate
}: AdminSettingsCardProps) {
  const enableFeeSplitsForOrders = useBooleanFlag(
    LaunchDarklyFlags.ENABLE_FEE_SPLITS_FOR_ORDERS
  );

  const [feeSplitType, setFeeSplitType] = useState(
    value?.convenienceFeeSplit?.type
  );

  const [showFeeSplitCard, setShowFeeSplitCard] = useState(
    !!value?.convenienceFeeSplit?.type
  );

  const showFlatFeeSplitInput = feeSplitType === 'flat';

  const showPercentFeeSplitInput = feeSplitType === 'percent';

  const [showPercentFeeSplitCap, setShowPercentFeeSplitCap] = useState(
    feeSplitType === 'percent' && valueIsNumber(value?.convenienceFeeSplit?.cap)
  );

  const feeSplitOptions = {
    full_waiver: `Full Waiver (no one pays)`,
    flat: `Flat Split (publishers pays up to a fixed amount)`,
    percent: `Percent Split (publisher pays a % of the fee)`
  };
  return (
    <CardGridLayout
      header={{
        title: 'Admin Settings',
        description: 'These settings are available to Column super users only.'
      }}
    >
      <SwitchControlledCard
        labelProps={{
          label: "Should we override Column's processing fee for this rate?",
          description:
            'This setting should only be changed with explicit confirmation from Richman.',
          value: value.enotice_fee_pct !== null,
          onChange: newVal =>
            onChange(
              newVal
                ? { ...value, enotice_fee_pct: 10 }
                : { ...value, enotice_fee_pct: null }
            )
        }}
        header=""
      >
        <GridInput>
          <TextField
            id="enotice-fee-pct"
            labelText="What is the processing fee (in %) for this rate?"
            value={(value.enotice_fee_pct || 0).toString()}
            type="number"
            inputMode="decimal"
            min={0}
            max={30}
            step={0.01}
            onChange={val =>
              onChange({ ...value, enotice_fee_pct: parseFloat(val || '0') })
            }
          />
        </GridInput>
      </SwitchControlledCard>
      {(isEditingNoticeRate || enableFeeSplitsForOrders) && (
        <SwitchControlledCard
          labelProps={{
            label:
              "Should a publisher fee split apply to Column's processing fee for this rate?",
            description:
              'This setting should only be changed with explicit confirmation from Richman.',
            value: showFeeSplitCard,
            onChange: newValue => {
              if (!newValue) {
                onChange({
                  ...value,
                  convenienceFeeSplit: undefined
                });
                setShowFeeSplitCard(false);
              } else {
                setShowFeeSplitCard(true);
              }
            }
          }}
          header="Fee Split Detail Settings"
        >
          {showFeeSplitCard && (
            <ColumnSelect
              id="rate-fee-split-type"
              labelText="What sort of fee split?"
              value={value.convenienceFeeSplit?.type}
              options={Object.entries(feeSplitOptions).map(([key, val]) => ({
                label: val,
                value: key
              }))}
              allowUndefined
              disabled={value.enotice_fee_pct === 0}
              required
              placeholder="No fee split type selected"
              onChange={newValue => {
                const newType = newValue as FeeSplit['type'];
                setFeeSplitType(newType);
                onChange({
                  ...value,
                  convenienceFeeSplit: {
                    type: newType,
                    amount: 0
                  }
                });
              }}
            />
          )}
          {showFlatFeeSplitInput && (
            <CurrencyTextField
              id="flat-fee-split-amount"
              labelText="What flat amount should the publisher pay?"
              initialValue={value.convenienceFeeSplit?.amount ?? 0}
              placeholder={`Enter a value in $`}
              onChange={newValue =>
                onChange({
                  ...value,
                  convenienceFeeSplit: {
                    type: 'flat',
                    amount: newValue
                  }
                })
              }
              required
            />
          )}
          {showPercentFeeSplitInput && (
            <GridInput>
              <TextField
                id="enotice-fee-split-pct-input"
                labelText="What % of the fee should the publisher pay?"
                value={(value.convenienceFeeSplit?.amount || 0).toString()}
                type="number"
                min={0}
                max={100}
                step={1}
                placeholder={`enter a value from 0 to 100`}
                required
                onChange={newVal =>
                  onChange({
                    ...value,
                    convenienceFeeSplit: {
                      type: 'percent',
                      amount: parseFloat(newVal || '0')
                    }
                  })
                }
              />
            </GridInput>
          )}
          {showPercentFeeSplitInput && (
            <LabeledSwitch
              label="Is there a per-notice fee cap (max amount) agreed upon with this publisher?"
              value={
                value.convenienceFeeSplit?.type === 'percent' &&
                valueIsNumber(value.convenienceFeeSplit.cap)
              }
              onChange={newVal => {
                onChange({
                  ...value,
                  convenienceFeeSplit: {
                    amount: value.convenienceFeeSplit?.amount ?? 0,
                    type: 'percent',
                    cap: newVal ? 0 : undefined
                  }
                });
                setShowPercentFeeSplitCap(newVal);
              }}
              disabled={value.convenienceFeeSplit?.type !== 'percent'}
            />
          )}
          {showPercentFeeSplitCap && showPercentFeeSplitInput && (
            <CurrencyTextField
              id="percent-fee-split-cap"
              labelText="Fee cap per notice (in $)"
              initialValue={value.convenienceFeeSplit?.cap}
              onChange={newValue =>
                onChange({
                  ...value,
                  convenienceFeeSplit: {
                    amount: value.convenienceFeeSplit?.amount ?? 0,
                    type: 'percent',
                    cap: newValue
                  }
                })
              }
              required
            />
          )}
        </SwitchControlledCard>
      )}
      {isEditingNoticeRate && (
        <SwitchControlledCard
          labelProps={{
            label: `Should we include a flat amount in Column's processing fee for this rate?`,
            description:
              'This setting should only be changed with explicit confirmation from Richman.',
            value: value.convenienceFeeFlat !== undefined,
            onChange: newVal =>
              onChange({
                ...value,
                convenienceFeeFlat: newVal ? 100 : undefined
              })
          }}
          header="Column Fee Settings"
        >
          <CurrencyTextField
            id="column-fee-flat"
            labelText="What flat amount should Column charge?"
            initialValue={value.convenienceFeeFlat ?? 0}
            onChange={newValue =>
              onChange({ ...value, convenienceFeeFlat: newValue })
            }
            required
          />
        </SwitchControlledCard>
      )}
    </CardGridLayout>
  );
}
