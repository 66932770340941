/* eslint-disable no-use-before-define */
/* eslint-disable max-classes-per-file */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { get as lodashGet } from 'lodash';
import { Collections } from '../constants';
import { dateTimeLikeToDate } from '../date';
import {
  ESnapshotExists,
  ESnapshot,
  EFirebaseContext,
  Customer,
  CustomerOrganization,
  Event,
  EInvite,
  EOrganization,
  EUser,
  ERate,
  ECard,
  ECardInvoice,
  ECardTransaction,
  EInvoice,
  EInvoiceItem,
  ENotice,
  ENoticeDraft,
  ENotification,
  EPayout,
  EPublicNotice,
  ESubscription,
  ETemplate,
  ETransfer,
  EPreviewNotice,
  FirebaseTimestamp,
  EDisplaySite,
  Notarization,
  EmailConfirmation,
  EDeadline,
  EJoinRequest,
  EUploadID,
  ENoticeFile,
  ETransaction,
  ECache,
  ECacheEntry,
  EMigration,
  EDocumentData,
  EPartialDocumentData,
  ModularSize,
  AffidavitTemplate,
  Note,
  EUpdateSettingRequest,
  AdRate,
  LedgerItem,
  EAdjudicationArea
} from '../types';
import {
  WhereFilterOp,
  EQuery,
  OrderByDirection,
  EQuerySnapshot,
  EUnsubscribe,
  ECollectionRef,
  ERef
} from '../types/firebase';
import { FtpFile } from '../types/ftpFile';
import { ECacheValue, ECacheKey } from '../types/integrations/caches';
import { InvoiceTransaction } from '../types/invoiceTransaction';
import { PublicationIssue } from '../types/publicationIssue';
import { PublicationIssueAttachment } from '../types/publicationIssueAttachment';
import { isRef } from '../model/refs';
import { Run } from '../types/runs';
import { EEdition } from '../types/eedition';
import { Obituary } from '../types/obituary';
import { Order } from '../types/order';
import { NewspaperOrder } from '../types/newspaperOrder';
import { Invoice, PublicNoticeInvoice } from '../types/invoices';
import { PublicationIssueSection } from '../types/publicationIssueSection';
import {
  ProductPublishingSetting,
  PublishingSetting
} from '../types/publishingSetting';
import { OrderFilingType } from '../types/filingType';
import { Classified } from '../types/classified';
import { ProductSiteSetting } from '../types/productSiteSetting';
import { OrderDetail } from '../types/orderDetail';
import { Coupon } from '../types/coupon';
import { PaperCheck } from '../types/paperCheck';
import { ExternalAds } from '../types/externalAds';
import { ExpressEmailConversation } from '../types/expressEmailConversation';

export const expectFieldValueDelete = () => {
  // This relies on the specific mock implementation of fieldValue() in firebaseMockUtils.
  // The real Firebase SDK has no such __name property on FieldValue
  return expect.objectContaining({ __name: 'delete' });
};

export const expectFieldValueServerTimestamp = () => {
  // This relies on the specific mock implementation of fieldValue() in firebaseMockUtils.
  // The real Firebase SDK has no such __name property on FieldValue
  return expect.objectContaining({ __name: 'serverTimestamp' });
};

export const getIdAndPathFromIdMaybeWithPath = (idMaybeWithPath: string) => {
  const parsedPath = idMaybeWithPath.startsWith('/')
    ? idMaybeWithPath.substring(1)
    : idMaybeWithPath;
  let parsedId: string;
  if (parsedPath.indexOf('/') !== -1) {
    const parsedPathSplit = parsedPath.split('/');
    parsedId = parsedPathSplit[parsedPathSplit.length - 1];
  } else {
    parsedId = parsedPath;
  }
  return { id: parsedId, path: parsedPath };
};

class MockFirebaseTransaction implements ETransaction {
  get<T>(ref: ERef<T>): Promise<ESnapshot<T>>;

  get<T>(query: EQuery<T>): Promise<EQuerySnapshot<T>>;

  get<T>(
    refOrQuery: ERef<T> | EQuery<T>
  ): Promise<ESnapshot<T> | EQuerySnapshot<T>> {
    if (isRef(refOrQuery)) {
      return refOrQuery.get();
    }
    return refOrQuery.get();
  }

  add<T>(ref: ECollectionRef<T>, data: EDocumentData<T>) {
    return ref.add(data);
  }

  set<T>(ref: ERef<T>, data: EDocumentData<T>) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    ref.set(data);
    return this;
  }

  update<T>(ref: ERef<T>, data: EPartialDocumentData<T>) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    ref.update(data);
    return this;
  }

  delete<T>(ref: ERef<T>) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    ref.delete();
    return this;
  }
}

export class MockFirebaseContext implements EFirebaseContext {
  private collections: Record<string, MockCollectionRef<unknown>> = {};

  fieldValue(name?: string) {
    return {
      __name: name,
      serverTimestamp: () => this.fieldValue('serverTimestamp'),
      arrayRemove: () => this.fieldValue('arrayRemove'),
      arrayUnion: () => this.fieldValue('arrayUnion'),
      delete: () => this.fieldValue('delete'),
      increment: () => this.fieldValue('increment'),
      isEqual: () => true
    };
  }

  timestamp(options?: { seconds: number; nanoseconds?: number }) {
    return options
      ? mockTimestampWithNanos(options.seconds, options.nanoseconds)
      : mockTimestampWithNanos(Date.now() / 1000, 0);
  }

  timestampFromDate(date: Date) {
    return mockTimestamp(date);
  }

  /**
   * A testing only method to get or create a collection reference.
   */
  collection<T>(collectionPath: string): MockCollectionRef<T> {
    if (!this.collections[collectionPath]) {
      this.collections[collectionPath] = mockCollectionRef<T>(collectionPath);
    }

    return this.collections[collectionPath] as MockCollectionRef<T>;
  }

  /**
   * A testing only methods to clear all docs in the context.
   */
  clearData() {
    const collectionRefs = Object.values(this.collections);
    for (const cr of collectionRefs) {
      cr.setDocs([]);
    }
  }

  doc<T>(path: string): ERef<T> {
    const segments = path.split('/').filter(Boolean);
    if (segments.length < 2 || segments.length % 2 !== 0) {
      throw new Error(`Invalid doc() path: ${path}`);
    }
    const currentCollection: MockCollectionRef<any> = this.collection(
      segments.slice(0, -1).join('/')
    );

    const currentRef: ERef<any> = currentCollection.doc(
      segments[segments.length - 1]
    );
    return currentRef as ERef<T>;
  }

  runTransaction<T>(handler: (t: ETransaction) => Promise<T>) {
    return handler(new MockFirebaseTransaction());
  }

  adTemplatesRef() {
    return this.collection<ETemplate>(Collections.adTemplates);
  }

  affidavitTemplatesRef() {
    return this.collection<AffidavitTemplate>(Collections.affidavitTemplates);
  }

  cachesRef<K extends ECacheKey, V extends ECacheValue>(
    parent: ERef<EOrganization>
  ): MockCollectionRef<ECache<K, V>> {
    return this.collection<ECache<K, V>>(
      `${parent.path}/${Collections.caches}`
    );
  }

  cacheEntriesRef<K extends ECacheKey, V extends ECacheValue>(
    parent: ERef<ECache<K, V>>
  ): MockCollectionRef<ECacheEntry<K, V>> {
    return this.collection<ECacheEntry<K, V>>(
      `${parent.path}/${Collections.cacheEntries}`
    );
  }

  cardsRef() {
    return this.collection<ECard>(Collections.cards);
  }

  cardInvoicesRef() {
    return this.collection<ECardInvoice>(Collections.cardInvoices);
  }

  notesRef() {
    return this.collection<Note>(Collections.notes);
  }

  mediaRef() {
    return this.collection<any>(Collections.media);
  }

  cardTransactionsRef() {
    return this.collection<ECardTransaction>(Collections.cardTransactions);
  }

  ledgerRef(): ECollectionRef<LedgerItem> {
    return this.collection<LedgerItem>(Collections.ledger);
  }

  classifiedsRef() {
    return this.collection<Classified>(Collections.classifieds);
  }

  customersRef() {
    return this.collection<Customer>(Collections.customers);
  }

  customerOrganizationsRef() {
    return this.collection<CustomerOrganization>(
      Collections.customerOrganizations
    );
  }

  deadlinesCollectionGroupRef() {
    return this.collection<EDeadline>(Collections.deadlines);
  }

  displaySitesRef() {
    return this.collection<EDisplaySite>(Collections.displaySites);
  }

  displaySiteUploadIDsRef(parent: ERef<EDisplaySite>) {
    return this.collection<EUploadID>(
      `${parent.path}/${Collections.uploadIDs}`
    );
  }

  eeditionsRef<T extends EEdition = EEdition>() {
    return this.collection<T>(Collections.eeditions);
  }

  emailConfirmationsRef() {
    return this.collection<EmailConfirmation>(Collections.emailConfirmations);
  }

  eventsRef<T extends Event = Event>(): MockCollectionRef<T> {
    return this.collection<T>(Collections.events);
  }

  filingTypesRef() {
    return this.collection<OrderFilingType>(Collections.filingTypes);
  }

  ftpFilesRef() {
    return this.collection<FtpFile>(Collections.ftpFiles);
  }

  invitesRef() {
    return this.collection<EInvite>(Collections.invites);
  }

  invoicesRef<T extends Invoice = PublicNoticeInvoice>(): MockCollectionRef<T> {
    return this.collection<T>(Collections.invoices);
  }

  invoiceItemsRef() {
    return this.collection<EInvoiceItem>(Collections.invoiceItems);
  }

  invoiceTransactionsRef(parent: ERef<EInvoice>) {
    return this.collection<InvoiceTransaction>(
      `${parent.path}/${Collections.invoiceTransactions}`
    );
  }

  invoiceTransactionsCollectionGroupRef() {
    return this.collection<InvoiceTransaction>(Collections.invoiceTransactions);
  }

  migrationsRef() {
    return this.collection<EMigration>(Collections.migrations);
  }

  notificationsRef() {
    return this.collection<ENotification>(Collections.notifications);
  }

  newspaperOrdersRef() {
    return this.collection<NewspaperOrder>(Collections.newspaperOrders);
  }

  newspaperOrdersCollectionGroupRef() {
    return this.collection<NewspaperOrder>(Collections.newspaperOrders);
  }

  obituariesRef() {
    return this.collection<Obituary>(Collections.obituaries);
  }

  ordersRef() {
    return this.collection<Order>(Collections.orders);
  }

  orderNewspaperOrdersRef(parent: ERef<Order>) {
    return this.collection<NewspaperOrder>(
      `${parent.path}/${Collections.newspaperOrders}`
    );
  }

  organizationsRef() {
    return this.collection<EOrganization>(Collections.organizations);
  }

  organizationDeadlinesRef(parent: ERef<EOrganization>) {
    return this.collection<EDeadline>(
      `${parent.path}/${Collections.deadlines}`
    );
  }

  organizationProductPublishingSettingsRef(parent: ERef<EOrganization>) {
    return this.collection<ProductPublishingSetting>(
      `${parent.path}/${Collections.productPublishingSettings}`
    );
  }

  modularSizesRef() {
    return this.collection<ModularSize>(Collections.modularSizes);
  }

  payoutsRef<T extends EPayout = EPayout>(): MockCollectionRef<T> {
    return this.collection<T>(Collections.payouts);
  }

  publicNoticesRef() {
    return this.collection<EPublicNotice>(Collections.publicNotices);
  }

  previewNoticesRef() {
    return this.collection<EPreviewNotice>(Collections.previewNotices);
  }

  productPublishingSettingsCollectionGroupRef() {
    return this.collection<ProductPublishingSetting>(
      Collections.productPublishingSettings
    );
  }

  publicationIssuesRef() {
    return this.collection<PublicationIssue>(Collections.publicationIssues);
  }

  publicationIssueAttachmentsRef(
    parent: ERef<PublicationIssue>
  ): ECollectionRef<PublicationIssueAttachment> {
    return this.collection<PublicationIssueAttachment>(
      `${parent.path}/${Collections.publicationIssueAttachments}`
    );
  }

  publicationIssueSectionsCollectionGroupRef() {
    return this.collection<PublicationIssueSection>(
      Collections.publicationIssueSections
    );
  }

  publicationIssueSectionsRef(parent: ERef<PublicationIssue>) {
    return this.collection<PublicationIssueSection>(
      `${parent.path}/${Collections.publicationIssueSections}`
    );
  }

  publishingSettingsRef() {
    return this.collection<PublishingSetting>(Collections.publishingSettings);
  }

  adRatesRef() {
    return this.collection<AdRate>(Collections.rates);
  }

  ratesRef() {
    return this.collection<ERate>(Collections.rates);
  }

  runsRef() {
    return this.collection<Run>(Collections.runs);
  }

  subscriptionsRef() {
    return this.collection<ESubscription>(Collections.subscriptions);
  }

  transfersRef() {
    return this.collection<ETransfer>(Collections.transfers);
  }

  userNoticesRef() {
    return this.collection<ENotice>(Collections.userNotices);
  }

  userNoticeFilesRef(parent: ERef<ENotice> | ERef<ENoticeDraft>) {
    return this.collection<ENoticeFile>(
      `${parent.path}/${Collections.noticeFiles}`
    );
  }

  userDraftsRef() {
    return this.collection<ENoticeDraft>(Collections.userDrafts);
  }

  usersRef() {
    return this.collection<EUser>(Collections.users);
  }

  notarizationsRef<T extends Notarization = Notarization>() {
    return this.collection<T>(Collections.notarizations);
  }

  joinRequestsRef() {
    return this.collection<EJoinRequest>(Collections.joinRequests);
  }

  stripeEventsRef() {
    // TODO: update this return type once we clean up our Stripe event & object types!
    return this.collection<any>(Collections.stripeevents);
  }

  updateSettingRequestsRef() {
    return this.collection<EUpdateSettingRequest>(
      Collections.updateSettingRequests
    );
  }

  adjudicationAreasRef() {
    return this.collection<EAdjudicationArea>(Collections.adjudicationAreas);
  }

  productSiteSettingsCollectionGroupRef() {
    return this.collection<ProductSiteSetting>(Collections.productSiteSettings);
  }

  organizationProductSiteSettingsRef(parent: ERef<EOrganization>) {
    return this.collection<ProductSiteSetting>(
      `${parent.path}/${Collections.productSiteSettings}`
    );
  }

  couponsRef() {
    return this.collection<Coupon>(Collections.coupons);
  }

  orderDetailsCollectionGroupRef() {
    return this.collection<OrderDetail>(Collections.orderDetails);
  }

  orderOrderDetailsRef(parent: ERef<Order>) {
    return this.collection<OrderDetail>(
      `${parent.path}/${Collections.orderDetails}`
    );
  }

  paperChecksRef() {
    return this.collection<PaperCheck>(Collections.paperChecks);
  }

  externalAdsRef<T extends ExternalAds = ExternalAds>() {
    return this.collection<T>(Collections.externalAds);
  }

  expressEmailConversationsRef() {
    return this.collection<ExpressEmailConversation>(
      Collections.expressEmailConversations
    );
  }

  // CODEGEN: MOCK-FIREBASE-CONTEXT - DO NOT DELETE OR MOVE
}

export class MockCollectionRef<T> implements ECollectionRef<T> {
  id: string;

  parent: ERef<unknown> | null;

  path: string;

  private docs: MockRef<T>[];

  constructor(path: string, parent: ERef<unknown> | null, docs: MockRef<T>[]) {
    this.id = path;
    this.parent = parent;
    this.path = path;
    this.docs = [];
    this.setDocs(docs);
  }

  /**
   * A testing-only method to append docs to the collection.
   */
  addDocs(docs: MockRef<T>[]) {
    this.docs = [...this.docs, ...docs];
  }

  /**
   * A testing-only method to retrieve all docs in the collection.
   */
  getDocs() {
    return this.docs;
  }

  /**
   * A testing-only method to override all docs in the collection.
   */
  setDocs(docs: MockRef<T>[]) {
    this.docs = [...docs];
  }

  /**
   * A testing-only methods to delete a doc from a collection.
   */
  deleteDoc(id: string) {
    this.docs = this.docs.filter(doc => doc.id !== id);
  }

  doc(documentPath?: string): MockRef<T> {
    const docId = documentPath ?? `${Math.round(Math.random() * 10000000)}`;
    let foundDoc = this.docs.find(doc => doc.id === docId);
    if (!foundDoc) {
      foundDoc = new LinkedMockRef<T>(docId, null, this);
      this.docs = [...this.docs, foundDoc];
    }

    return foundDoc;
  }

  async add(data: EPartialDocumentData<T>) {
    const ref = this.doc();
    await ref.set(data);
    return ref;
  }

  where(fieldPath: string, opStr: WhereFilterOp, initialValue: any): EQuery<T> {
    // coalesce timestamps or other date like to date
    let value = initialValue;
    if (value?.toDate) {
      value = dateTimeLikeToDate(value);
    }

    const docs = Object.values(this.docs);

    const filteredDocs = docs.filter(d => {
      const data = d.getSnap().data() as Record<string, any>;

      const fieldIsName =
        fieldPath === '__name__' ||
        (typeof fieldPath === 'object' &&
          (fieldPath as any)?.segments?.[0] === '__name__');

      let val = fieldIsName ? d.id : lodashGet(data, fieldPath);
      if (val === undefined) {
        return false;
      }
      if (val?.toDate) {
        val = dateTimeLikeToDate(val);
      }
      const valueIsRef = isRef(value);

      // TODO(Ari): add a check for ref to all cases
      switch (opStr) {
        case '==':
          return valueIsRef ? val.id === value.id : val === value;
        case '!=':
          return val !== value;
        case '<':
          return val < value;
        case '<=':
          return val <= value;
        case '>':
          return val > value;
        case '>=':
          return val >= value;
        case 'in':
          return (value as any[]).some(v =>
            isRef(v) ? v.id === val.id : v === val
          );
        case 'not-in':
          return !(value as any[]).includes(val);
        case 'array-contains':
          return valueIsRef
            ? (val as ERef<any>[]).some(ref => ref.id === value.id)
            : (val as any[]).includes(value);
        case 'array-contains-any':
          return (value as any[]).some(v => (val as any[]).includes(v));
        default:
          return false;
      }
    });

    return new MockCollectionRef<T>(this.path, this.parent, filteredDocs);
  }

  orderBy(
    fieldPath: string,
    directionStr?: OrderByDirection | undefined
  ): EQuery<T> {
    const sortFn = (a: MockRef<T>, b: MockRef<T>): number => {
      const dataA = a.getSnap().data() as Record<string, any>;
      const dataB = b.getSnap().data() as Record<string, any>;

      const dataValueIsFirebaseTimestamp = (
        value: any
      ): value is FirebaseTimestamp => {
        const valueIsObject = typeof value === 'object';
        if (!valueIsObject) return false;

        return !!(value || {}).toMillis;
      };

      const getComparableValue = (value: any) => {
        if (dataValueIsFirebaseTimestamp(value)) {
          return value.toMillis();
        }

        return value;
      };

      const valueA = getComparableValue(dataA[fieldPath]);
      const valueB = getComparableValue(dataB[fieldPath]);
      const comparison =
        directionStr === 'desc' ? valueA < valueB : valueA > valueB;

      return dataA[fieldPath] === dataB[fieldPath] ? 0 : comparison ? 1 : -1;
    };
    const docs = Object.values(this.docs);
    const filteredDocs = docs.filter(d => {
      const data = d.getSnap().data() as Record<string, any>;
      const val = lodashGet(data, fieldPath);
      if (val === undefined) {
        return false;
      }
      return true;
    });

    filteredDocs.sort(sortFn);

    return new MockCollectionRef<T>(this.path, this.parent, filteredDocs);
  }

  limit(limit: number): EQuery<T> {
    return new MockCollectionRef<T>(
      this.path,
      this.parent,
      this.docs.slice(0, limit)
    );
  }

  limitToLast(limit: number): EQuery<T> {
    return this;
  }

  startAt(...fieldValues: any[]): EQuery<T> {
    return this;
  }

  startAfter(doc: ESnapshotExists<T>): EQuery<T> {
    const docs = Object.values(this.docs);
    const startIndex = docs.findIndex(d => d.id === doc.id);
    return new MockCollectionRef<T>(
      this.path,
      this.parent,
      docs.slice(startIndex + 1)
    );
  }

  endBefore(...fieldValues: any[]): EQuery<T> {
    return this;
  }

  endAt(...fieldValues: any[]): EQuery<T> {
    return this;
  }

  async get(): Promise<EQuerySnapshot<T>> {
    const docs = Object.values(this.docs).map(ref => ref.getSnap());
    return {
      docs,
      size: docs.length,
      empty: docs.length === 0
    };
  }

  onSnapshot(
    onNext: (snapshot: EQuerySnapshot<T>) => void,
    onError?: ((error: any) => void) | undefined
  ): EUnsubscribe {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.get().then(snap => onNext(snap));
    return () => {};
  }
}

export interface RefMethods<T> {
  update?: (arg: Partial<T>) => Promise<any>;
  set?: (arg: Partial<T>) => Promise<any>;
  delete?: () => Promise<any>;
  collection?: (arg: string) => any;
  onSnapshot?: (arg: any) => any;
}

export interface MockRef<T> extends ERef<T> {
  getSnap(): ESnapshotExists<T>;
}

/**
 * An implementation of MockRef that is linked to a MockCollectionRef
 */
export class LinkedMockRef<T> implements MockRef<T> {
  id: string;

  path: string;

  parent: MockCollectionRef<T>;

  private data: T | Partial<T> | null;

  /**
   * A test only method to avoid await get()
   */
  getSnap() {
    return mockSnapshotExists(this.id, this.data as T, this);
  }

  constructor(
    id: string,
    data: T | Partial<T> | null,
    parent: MockCollectionRef<T>
  ) {
    this.id = id;
    this.path = `${parent.path}/${id}`;
    this.data = data;
    this.parent = parent;
  }

  collection(collectionPath: string): ECollectionRef<any> {
    return MOCK_FIREBASE_CONTEXT.collection(`${this.path}/${collectionPath}`);
  }

  async update(update: Partial<T>): Promise<any> {
    this.data = {
      ...this.data,
      ...update
    };
  }

  async set(update: Partial<T>): Promise<any> {
    this.data = update;
  }

  async get(): Promise<ESnapshot<T>> {
    if (this.data === null) {
      return mockSnapshot(this.id, null, this);
    }
    return this.getSnap();
  }

  async delete(): Promise<any> {
    this.parent.deleteDoc(this.id);
  }

  onSnapshot(onNext: (snapshot: ESnapshot<T>) => void): EUnsubscribe {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.get().then(snap => onNext(snap));
    return () => {};
  }
}

/**
 * Get a fully local ESnapshotExists which contains the given data.
 *
 * Note: it's generally better to call mockRef() and use .get() on the
 * result than to call this function directly.
 */
export function mockSnapshotExists<T>(
  id: string,
  data: T | Partial<T>,
  ref?: ERef<T>
): ESnapshotExists<T> {
  return {
    id,
    exists: true,
    data: () => data as T,
    get ref() {
      if (ref) {
        return ref;
      }
      throw new Error('ref unimplemented!');
    }
  };
}

/**
 * Get a fully local ESnapshot that can represent a non-existent doc if there is
 * no data.
 *
 * Note: it's generally better to call mockRef() with null data and use .get()
 * on the result than to call this function directly.
 */
export function mockSnapshot<T>(
  id: string,
  data: T | Partial<T> | null,
  ref?: ERef<T>
): ESnapshot<T> {
  if (data === null)
    return {
      id,
      exists: false,
      data: () => undefined,
      get ref() {
        if (ref) {
          return ref;
        }
        throw new Error('ref unimplemented!');
      }
    };

  return mockSnapshotExists(id, data, ref);
}

/**
 * Get a fully local ERef object which will return the given data when get() is called.
 * Other methods (update, set, delete) can be mocked out via the methods argument.
 */
export function mockRef<T>(
  idMaybeWithPath: string,
  data: T | Partial<T> | null,
  parent?: any,
  methods?: RefMethods<T>
): MockRef<T> {
  const unimplemented = (name: string) => () => {
    throw new Error(`${name}() unimplemented for ${idMaybeWithPath}!`);
  };
  const unimplementedAsync = (name: string) => async () => unimplemented(name);
  const { id, path: parsedPath } =
    getIdAndPathFromIdMaybeWithPath(idMaybeWithPath);

  let currentData = data;

  return {
    id,
    path: parent ? `${parent.path}/${parsedPath}` : parsedPath,

    getSnap() {
      return mockSnapshotExists(id, currentData as T, this);
    },

    async get() {
      if (currentData === null) return mockSnapshot(id, currentData, this);
      return this.getSnap();
    },

    update:
      methods?.update ||
      ((updates: EPartialDocumentData<T>) => {
        if (currentData === null) {
          return Promise.reject(
            new Error('Cannot update a non-existent document!')
          );
        }
        currentData = { ...currentData, ...updates };
        return Promise.resolve();
      }),

    set:
      methods?.set ||
      ((newData: EPartialDocumentData<T>) => {
        currentData = { ...newData } as T | Partial<T>;
        return Promise.resolve();
      }),

    delete: methods?.delete || unimplementedAsync('delete'),

    collection:
      methods?.collection ||
      ((name: string) => {
        return MOCK_FIREBASE_CONTEXT.collection(`${parsedPath}/${name}`);
      }),

    onSnapshot(onNext) {
      if (methods?.onSnapshot) {
        return methods.onSnapshot(onNext);
      }
      onNext(this.getSnap());
      return () => {};
    },

    get parent() {
      if (parent) {
        return parent;
      }
      return undefined as any;
    }
  };
}

export function mockTimestamp(date: Date): FirebaseTimestamp {
  return {
    seconds: date.getTime() / 1000,
    nanoseconds: 0,
    toDate: () => date,
    toMillis: () => date.getTime(),
    isEqual: other => other.toMillis() === date.getTime(),
    valueOf: () => date.toISOString()
  };
}

export function mockTimestampWithNanos(
  seconds: number,
  nanoseconds = 0
): FirebaseTimestamp {
  const date = new Date(seconds);
  return {
    seconds,
    nanoseconds,
    toDate: () => date,
    toMillis: () => date.getTime(),
    isEqual: other => other.toMillis() === date.getTime(),
    valueOf: () => date.toISOString()
  };
}

export function mockCollectionRef<T>(
  path: string,
  parent?: ERef<unknown> | null
): MockCollectionRef<T> {
  let resultParent: ERef<unknown> | null | undefined = parent;
  // Infer the parent if the path has an even number of slashes and parent is not defined
  const slashCount = (path.match(/\//g) || []).length;
  if (!parent && slashCount % 2 === 0 && slashCount > 0) {
    const parentPath = path.substring(0, path.lastIndexOf('/'));
    resultParent = MOCK_FIREBASE_CONTEXT.doc(parentPath);
  }
  return new MockCollectionRef<T>(path, resultParent || null, []);
}

const MOCK_FIREBASE_CONTEXT = new MockFirebaseContext();

export function mockFirebaseContext(): MockFirebaseContext {
  return MOCK_FIREBASE_CONTEXT;
}
