import { useBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import {
  FilingType,
  isNoticeFilingType,
  isOrderFilingType
} from 'lib/types/filingType';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { AdRate, EOrganization, ESnapshotExists } from 'lib/types';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import LabeledSwitch from 'lib/components/LabeledSwitch';
import { Product } from 'lib/enums';
import { PRODUCT_TO_NAME } from 'lib/enums/Product';
import { InheritableSettingSelectInput } from '../../InheritableSettingSelectInput';

const DEFAULT_RATE_DESCRIPTION =
  'Default - use organization default rate or customer specific rates';

/**
 * Converts a set of rates into a set of options for a select input
 */
const ratesToRateSelectOptions = (
  rates: ESnapshotExists<AdRate>[],
  includeDefaultOption: boolean
) => {
  const rateOptions = includeDefaultOption
    ? [
        {
          label: DEFAULT_RATE_DESCRIPTION,
          value: 'default'
        }
      ]
    : [];
  rates.forEach(rate => {
    const label = rate.data().archived
      ? `${rate.data().description} (archived)`
      : rate.data().description;
    rateOptions.push({
      value: rate.id,
      label
    });
  });
  return rateOptions;
};

type BillingSettingsCardProps<T extends FilingType> = {
  onUpdateNoticeType: (newValue: T) => void;
  activeOrganization: ESnapshotExists<EOrganization>;
  updatedNoticeType: T;
  rates: ESnapshotExists<AdRate>[];
  productLine: Product;
};

export default function BillingSettingsCard<T extends FilingType>({
  onUpdateNoticeType,
  activeOrganization,
  updatedNoticeType,
  productLine,
  rates
}: BillingSettingsCardProps<T>) {
  const enableOrderFilingTypeDisplayRateConfiguration = useBooleanFlag(
    LaunchDarklyFlags.ENABLE_ORDER_FILING_TYPE_DISPLAY_RATE_CONFIGURATION,
    false
  );
  return (
    <CardGridLayout
      header={{
        title: 'Billing Settings',
        description: `Decide how to charge customers for this ${PRODUCT_TO_NAME[
          productLine
        ].singular.toLowerCase()} type.`
      }}
    >
      <GridInput fullWidth>
        <ColumnSelect
          id="notice-type-rate-select"
          labelText="Default rate"
          options={ratesToRateSelectOptions(
            rates,
            isNoticeFilingType(updatedNoticeType)
          )}
          placeholder={DEFAULT_RATE_DESCRIPTION}
          value={updatedNoticeType.rate?.id}
          onChange={newValue => {
            const newNoticeType = { ...updatedNoticeType };
            if (newValue !== 'default') {
              newNoticeType.rate = rates.find(r => r.id === newValue)?.ref;
            } else {
              delete newNoticeType.rate;
            }
            onUpdateNoticeType(newNoticeType);
          }}
          allowUndefined
          required={productLine !== Product.Notice}
        />
      </GridInput>
      {enableOrderFilingTypeDisplayRateConfiguration &&
        isOrderFilingType(updatedNoticeType) && (
          <GridInput fullWidth>
            <ColumnSelect
              id={'default-rate-for-display-layouts'}
              labelText={'Default rate for display layouts'}
              options={ratesToRateSelectOptions(rates, true)}
              placeholder={DEFAULT_RATE_DESCRIPTION}
              value={
                updatedNoticeType.rateConfiguration?.defaultDisplayRate?.id
              }
              onChange={newValue => {
                const newNoticeType = { ...updatedNoticeType };
                newNoticeType.rateConfiguration = {
                  ...newNoticeType.rateConfiguration,
                  defaultDisplayRate:
                    newValue !== 'default'
                      ? rates.find(r => r.id === newValue)?.ref
                      : null
                };
                onUpdateNoticeType(newNoticeType);
              }}
              allowUndefined
            />
          </GridInput>
        )}
      {isNoticeFilingType(updatedNoticeType) && (
        <>
          <GridInput fullWidth>
            <LabeledSwitch
              label="Require upfront payment?"
              description="If enabled, notices of this type will require upfront payment unless turned off for a customer or a specific notice."
              value={updatedNoticeType.upFrontPayment || false}
              onChange={newValue =>
                onUpdateNoticeType({
                  ...updatedNoticeType,
                  upFrontPayment: newValue
                })
              }
            />
          </GridInput>
          <GridInput fullWidth>
            <LabeledSwitch
              label="Automatically generate invoices?"
              description="If enabled, invoices will be created automatically upon notice submission."
              value={updatedNoticeType.autoInvoice || false}
              onChange={newValue =>
                onUpdateNoticeType({
                  ...updatedNoticeType,
                  autoInvoice: newValue
                })
              }
            />
          </GridInput>
          {activeOrganization.data().allowInvoiceOutsideColumn && (
            <GridInput fullWidth>
              <InheritableSettingSelectInput
                id="invoice-outside-column"
                labelText="Enable invoicing outside Column?"
                optionText={{
                  yesLabel: `Yes — publisher will bill and collect payment outside of Column`,
                  noLabel: `No — create invoices and collect payment inside Column`,
                  defaultLabel:
                    'Default — use default newspaper or customer settings'
                }}
                value={updatedNoticeType.invoiceOutsideColumn}
                onChange={newValue => {
                  const newNoticeType = { ...updatedNoticeType };
                  if (newValue === undefined) {
                    delete newNoticeType.invoiceOutsideColumn;
                  } else {
                    newNoticeType.invoiceOutsideColumn = newValue;
                  }
                  onUpdateNoticeType(newNoticeType);
                }}
              />
            </GridInput>
          )}
        </>
      )}
    </CardGridLayout>
  );
}
