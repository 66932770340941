import React, { useState } from 'react';

import { exists } from 'lib/types';
import TabGroup, { TabOption } from 'lib/components/Tabs';
import SettingsPage from 'routes/settings/SettingsPage';
import { Product } from 'lib/enums';
import { PublishingMedium } from 'lib/enums/PublishingMedium';
import { selectActiveOrganization } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import LoadingState from 'components/LoadingState';
import AdTemplateTable from './adTemplateTable';
import { LayoutSettings } from './layoutSettings';
import { Preview } from './preview';

const TEMPLATES_TAB: TabOption = {
  label: 'Templates',
  enabled: true,
  id: 'templates-tab'
};

const LAYOUT_TAB: TabOption = {
  label: 'Layout',
  enabled: true,
  id: 'layout-tab'
};

const PREVIEW_TAB: TabOption = {
  label: 'Preview',
  enabled: true,
  id: 'preview-tab'
};

const DESIGN_AND_LAYOUT_TABS = [TEMPLATES_TAB, LAYOUT_TAB, PREVIEW_TAB];

export default function DesignAndLayoutSettings() {
  const activeOrganization = useAppSelector(selectActiveOrganization);
  const [activeTab, setActiveTab] = useState(TEMPLATES_TAB);

  if (!exists(activeOrganization)) {
    return <LoadingState />;
  }

  return (
    <SettingsPage>
      <TabGroup
        onClickTab={setActiveTab}
        activeTab={activeTab}
        tabs={DESIGN_AND_LAYOUT_TABS}
        id="newspaper-settings-design-and-layout-tabs"
      />
      {activeTab.label === TEMPLATES_TAB.label && (
        <AdTemplateTable
          activeOrganization={activeOrganization}
          productLine={Product.Notice}
          publishingMedium={PublishingMedium.Print}
        />
      )}
      {activeTab.label === LAYOUT_TAB.label && <LayoutSettings />}
      {activeTab.label === PREVIEW_TAB.label && <Preview />}
    </SettingsPage>
  );
}
