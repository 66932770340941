import { Layout } from '../../types/layout';

export class LayoutModel {
  layout: Layout;

  constructor(layout: Layout) {
    this.layout = layout;
  }

  get imageCount() {
    return this.layout.photos || this.layout.images?.length || 0;
  }

  get imageAspectRatioDecimal() {
    if (this.layout.aspectRatio === 'none') {
      return;
    }
    const [width, height] = this.layout.aspectRatio.split(' / ');
    return parseFloat(width) / parseFloat(height);
  }

  /**
   * Backwards compatible adapter to get image configuration either from `images` or the deprecated `photos` field
   */
  get imageConfig() {
    return this.layout.images
      ? this.layout.images
      : [...Array(this.layout.photos)].map(() => ({ position: 'top' }));
  }

  get hasImages() {
    return this.imageCount > 0;
  }

  get description() {
    return (
      this.layout.description ||
      `${this.layout.columns} columns, ${this.imageCount} photo${
        this.imageCount === 1 ? '' : 's'
      }`
    );
  }
}
