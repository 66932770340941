import { selectIsNoticePlacementButtonDisabled } from 'components/PlaceNoticeButton';
import { Product } from 'lib/enums';
import { PRODUCT_TO_NAME } from 'lib/enums/Product';
import { getDynamicRoute } from 'lib/frontend/utils/router';
import {
  selectHasClassifiedsActive,
  selectHasObituariesActive,
  selectHasPublicNoticesActive
} from 'redux/auth';
import { createSelector } from 'reselect';
import { MAIN_ROUTES, OBITUARY_ROUTES, CLASSIFIED_ROUTES } from 'router/routes';

export const selectOrdersTabItems = createSelector(
  [
    selectHasPublicNoticesActive,
    selectHasObituariesActive,
    selectHasClassifiedsActive
  ],
  (
    publicNoticesOrdersEnabled,
    obituariesOrdersEnabled,
    classifiedsOrdersEnabled
  ) => [
    {
      id: Product.Notice,
      path: MAIN_ROUTES.NOTICES,
      label: PRODUCT_TO_NAME[Product.Notice].plural,
      enabled: publicNoticesOrdersEnabled
    },
    {
      id: Product.Obituary,
      path: MAIN_ROUTES.OBITUARIES,
      label: PRODUCT_TO_NAME[Product.Obituary].plural,
      enabled: obituariesOrdersEnabled
    },
    {
      id: Product.Classified,
      path: MAIN_ROUTES.CLASSIFIEDS,
      label: PRODUCT_TO_NAME[Product.Classified].plural,
      enabled: classifiedsOrdersEnabled
    }
  ]
);

export const selectQuickCreateItems = createSelector(
  [
    selectHasPublicNoticesActive,
    selectHasObituariesActive,
    selectHasClassifiedsActive,
    selectIsNoticePlacementButtonDisabled
  ],
  (
    publicNoticesOrdersEnabled,
    obituariesOrdersEnabled,
    classifiedsOrdersEnabled,
    isNoticePlacementButtonDisabled
  ) => [
    {
      id: Product.Notice,
      path: MAIN_ROUTES.PLACE_NOTICE,
      label: `Place a ${PRODUCT_TO_NAME[Product.Notice].singular}`,
      enabled: publicNoticesOrdersEnabled && !isNoticePlacementButtonDisabled
    },
    {
      id: Product.Obituary,
      path: getDynamicRoute(OBITUARY_ROUTES.PLACE, { id: '' }),
      label: `Place an ${PRODUCT_TO_NAME[Product.Obituary].singular}`,
      enabled: obituariesOrdersEnabled
    },
    {
      id: Product.Classified,
      path: getDynamicRoute(CLASSIFIED_ROUTES.PLACE, { id: '' }),
      label: `Place a ${PRODUCT_TO_NAME[Product.Classified].singular}`,
      enabled: classifiedsOrdersEnabled
    }
  ]
);
