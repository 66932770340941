import { useBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { NewspaperOrdersFormData } from 'routes/ads/place/PlacementFlowStepSelector';
import { Layout } from 'lib/types/layout';
import { logAndCaptureCriticalError } from 'utils';
import { ColumnService } from 'lib/services/directory';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { getOrThrow } from 'lib/utils/refs';
import { getAdWidth } from 'lib/orders/layouts';
import { Ad } from 'lib/types/ad';
import { Product } from 'lib/enums';
import { minBy } from 'lodash';
import classNames from 'classnames';
import OrderImageEditor from '../Images/OrderImageEditor';
import { AdEditorData } from '../FormattedEditorWrapper';
import TitleEditor from './TitleEditor';
import ContentEditor, { MadlibEditorProps } from './ContentEditor';

type FormattedEditorProps<T extends Ad> = {
  adData: Partial<T>;
  onChange: (data: AdEditorData) => void;
  layout: Layout;
  newspaperOrdersFormData: NewspaperOrdersFormData;
  disableEditing: boolean;
  product: Product;
  madlibProps: MadlibEditorProps;
  forceFreeEdit: boolean;
};

function FormattedEditor<T extends Ad>({
  adData,
  onChange,
  layout,
  newspaperOrdersFormData,
  disableEditing,
  product,
  madlibProps,
  forceFreeEdit
}: FormattedEditorProps<T>) {
  const enableNewOrderImageEditor = useBooleanFlag(
    LaunchDarklyFlags.ENABLE_NEW_ORDER_IMAGE_EDITOR,
    false
  );

  const [firstNewspaperOrder] = newspaperOrdersFormData;

  const { value: adWidth, isLoading: isAdWidthLoading } = useAsyncEffect({
    fetchData: async () => {
      if (!newspaperOrdersFormData.length) return;
      const publisher = await getOrThrow(firstNewspaperOrder.newspaper);
      const template = await getOrThrow(publisher.data().adTemplate);
      const templateStyles = template.data().styles;
      if (!templateStyles) {
        logAndCaptureCriticalError(
          ColumnService.ORDER_PLACEMENT,
          new Error('No styles found for ad template'),
          'No styles found for ad template',
          {
            publisher: publisher.id,
            template: template.id
          }
        );
        return;
      }
      const { response: adWidth, error: adWidthError } = getAdWidth(
        layout,
        templateStyles
      );
      if (adWidthError) {
        logAndCaptureCriticalError(
          ColumnService.ORDER_PLACEMENT,
          adWidthError,
          'Error getting ad width'
        );
      }
      return adWidth;
    },
    dependencies: [
      layout.id,
      newspaperOrdersFormData.map(o => o.newspaper?.id).join(',')
    ]
  });

  const { value: maximumWordCountByFilingType, isLoading: loadingFilingType } =
    useAsyncEffect({
      fetchData: async () => {
        const filingTypeRefsPromise = newspaperOrdersFormData.map(orderForm =>
          getOrThrow(orderForm.filingType)
        );

        const filingTypes = await Promise.all(filingTypeRefsPromise);
        const filingTypeSnapshots = filingTypes.map(ftSnap => ftSnap.data());
        const applicableRange = minBy(
          filingTypeSnapshots,
          ft => ft.maxWordCount
        );
        if (!applicableRange) {
          return null;
        }
        return applicableRange.maxWordCount;
      },
      dependencies: [
        newspaperOrdersFormData.map(o => o.filingType?.id).join(',')
      ],
      errorConfig: {
        service: ColumnService.ORDER_PLACEMENT,
        message: 'Failed to load filing type ad data'
      }
    });

  if (isAdWidthLoading) return null;

  return (
    <div
      className={classNames(
        'bg-white origin-top lg:origin-top-left lg:mt-4 align-baseline transform border',
        {
          'lg:scale-150': layout.columns === 1,
          'lg:scale-125': layout.columns === 2,
          'lg:scale-110': layout.columns === 3
        }
      )}
      style={{
        width: `${adWidth}px`,
        alignSelf: 'baseline',
        borderColor:
          firstNewspaperOrder.colorOptions?.borderColor || 'transparent'
      }}
    >
      <div className="border border-dashed">
        <OrderImageEditor
          position="top"
          adData={adData}
          onChange={onChange}
          layout={layout}
          disableEditing={disableEditing}
        />
        <h1
          className="overflow-auto border-dashed border-b py-4 px-1"
          style={{
            backgroundColor:
              newspaperOrdersFormData[0].colorOptions?.backgroundColor ||
              'transparent'
          }}
        >
          <TitleEditor
            adData={adData}
            disableEditing={disableEditing}
            product={product}
            onChange={onChange}
            layout={layout}
          />
        </h1>
        {!layout.hideContent && (
          <>
            <div
              className="px-1"
              style={{
                backgroundColor:
                  newspaperOrdersFormData[0].colorOptions?.backgroundColor ||
                  'transparent'
              }}
            >
              <ContentEditor
                onChange={onChange}
                adData={adData}
                disableEditing={disableEditing || loadingFilingType}
                product={product}
                madlibProps={madlibProps}
                forceFreeEdit={forceFreeEdit}
                maxWords={maximumWordCountByFilingType}
                layout={layout}
              />
            </div>
          </>
        )}
        {enableNewOrderImageEditor && (
          <OrderImageEditor
            position="bottom"
            adData={adData}
            onChange={onChange}
            layout={layout}
            disableEditing={disableEditing}
          />
        )}
      </div>
    </div>
  );
}

export default FormattedEditor;
