import React, { useState } from 'react';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';
import { GridInput } from 'lib/components/Card/Grid';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { Form } from 'lib/components/Form';
import { SUBDOMAIN_REGEX } from 'lib/constants/validationRegex';
import { TextField } from 'lib/components/TextField';
import {
  PublishTrigger,
  ProductSiteSetting
} from 'lib/types/productSiteSetting';
import { Product } from 'lib/enums';
import { ColumnButton } from 'lib/components/ColumnButton';
import { Alert } from 'lib/components/Alert';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { CompactSwitchControlledCard } from 'lib/components/Card/CompactSwitchControlledCard';
import { PublishingMedium } from 'lib/enums/PublishingMedium';
import CheckboxGroup from 'lib/components/Checkbox/CheckboxGroup';
import SiteSettingsAdditionalLinks from './SiteSettingsAdditionalLinks';

type SiteSettingFormProps = {
  onSave: (siteSettingData: ProductSiteSetting) => Promise<boolean>;
  siteSetting: ProductSiteSetting | undefined;
  url: string | undefined | null;
  productLine: Product;
  loading: boolean;
};

const publishTriggerOptions = [
  {
    value: PublishTrigger.FIRST_PUBLISHING_DATE,
    label: `On the first publishing date`
  },
  { value: PublishTrigger.CONFIRMATION, label: 'When I confirm the order' }
];

export function SiteSettingForm({
  onSave,
  siteSetting,
  url,
  productLine,
  loading
}: SiteSettingFormProps) {
  const defaultPublishTrigger = PublishTrigger.FIRST_PUBLISHING_DATE;
  const [siteEnabled, setSiteEnabled] = useState(
    !(siteSetting?.disabled ?? true)
  );
  const [publishTrigger, setPublishTrigger] = useState(
    siteSetting?.publishTrigger ?? defaultPublishTrigger
  );
  const [subdomainName, setSubdomainName] = useState(
    siteSetting?.subdomainName || ''
  );
  const [gaToken, setGaToken] = useState(siteSetting?.gaToken || '');
  const [userError, setUserError] = useState('');
  const [additionalLinks, setAdditionalLinks] = useState(
    siteSetting?.additionalLinks || []
  );

  const [publishingMediums, setPublishingMediums] = useState(
    siteSetting?.publishingMediums
  );
  const [expirationSetting, setExpirationSetting] = useState(
    siteSetting?.expirationSetting
  );

  const enableAdditionalLinks =
    getBooleanFlag(LaunchDarklyFlags.ENABLE_ADDITIONAL_LINKS_ON_DISPLAY_SITE) &&
    productLine === Product.Classified;

  const handleSave = async (enabled: boolean) => {
    setUserError('');
    const newSiteSetting: ProductSiteSetting = {
      product: productLine,
      publishTrigger,
      subdomainName,
      disabled: !enabled,
      gaToken
    };
    if (enableAdditionalLinks) {
      newSiteSetting.additionalLinks = additionalLinks;
    }
    if (publishingMediums) {
      newSiteSetting.publishingMediums = publishingMediums;
    }
    if (expirationSetting) {
      newSiteSetting.expirationSetting = expirationSetting;
    } else {
      delete newSiteSetting.expirationSetting;
    }

    const success = await onSave(newSiteSetting);
    if (!success) {
      setSubdomainName(siteSetting?.subdomainName || '');
      setPublishTrigger(siteSetting?.publishTrigger ?? defaultPublishTrigger);
    }
  };

  const handleSiteEnabledChange = (newEnabledValue: boolean) => {
    setSiteEnabled(newEnabledValue);
    if (!newEnabledValue) {
      void handleSave(newEnabledValue); // Save immediately when disabling
    }
  };

  return (
    <Form onSubmit={() => handleSave(true)}>
      {userError && <Alert status="error" id="save-error" title={userError} />}
      {url ? (
        <GridInput fullWidth>
          <Alert
            status="success"
            id="site-online"
            title={
              <>
                Your site is online at:{' '}
                <a href={url} target="_blank" rel="noopener noreferrer">
                  {url}
                </a>
              </>
            }
          />
        </GridInput>
      ) : (
        <GridInput fullWidth>
          <Alert
            status="warning"
            id="site-offline"
            title="Your site is not online yet. Save your settings to get started."
          />
        </GridInput>
      )}
      <SwitchControlledCard
        labelProps={{
          label: `Enable ${productLine} site?`,
          description: `Enable your ${productLine} site, hosted by Column.`,
          onChange: handleSiteEnabledChange,
          value: siteEnabled
        }}
        header={`Site Settings`}
      >
        <GridInput>
          <TextField
            id="subdomainName"
            labelText="Subdomain Name"
            value={subdomainName}
            format={value => value.trim().toLowerCase()}
            onChange={setSubdomainName}
            type="text"
            pattern={SUBDOMAIN_REGEX}
            maxLength={63}
            validationMessages={{
              patternMismatch: 'Please enter a valid subdomain name'
            }}
            required
          />
        </GridInput>

        <GridInput>
          <TextField
            id="ga-token"
            labelText="Google Analytics Token"
            placeholder="G-..."
            value={gaToken}
            onChange={setGaToken}
            maxLength={40}
          />
        </GridInput>

        <GridInput fullWidth>
          <ColumnSelect<PublishTrigger>
            options={publishTriggerOptions}
            value={publishTrigger}
            onChange={setPublishTrigger}
            id="publish-trigger-select"
            labelText="Publish to the site..."
          />
        </GridInput>

        <CompactSwitchControlledCard
          labelProps={{
            label: 'Enable order expiration on display site',
            value: !!expirationSetting,
            onChange: () => {
              if (expirationSetting) {
                setExpirationSetting(undefined);
              } else {
                setExpirationSetting({
                  method: 'relative',
                  days: 30
                });
              }
            }
          }}
        >
          <div className="flex grid grid-cols-2 gap-4">
            <GridInput
              fullWidth={expirationSetting?.method === 'last-publication-date'}
            >
              <ColumnSelect
                options={[
                  {
                    value: 'relative',
                    label:
                      'Remove ads a fixed number of days after first publication online'
                  },
                  {
                    value: 'last-publication-date',
                    label: 'Remove ads after the last publication date'
                  }
                ]}
                value={expirationSetting?.method}
                onChange={newMethod => {
                  if (newMethod === 'relative') {
                    setExpirationSetting({
                      method: newMethod as 'relative',
                      days: 15
                    });
                  } else {
                    setExpirationSetting({
                      method: newMethod as 'last-publication-date'
                    });
                  }
                }}
                id="expiration-setting-select"
                labelText="Expiration setting"
              />
            </GridInput>
            {expirationSetting?.method === 'relative' && (
              <GridInput>
                <TextField
                  id="expiration-days"
                  labelText="Number of days"
                  type="number"
                  value={expirationSetting.days?.toString()}
                  min={1}
                  max={365}
                  onChange={value => {
                    setExpirationSetting({
                      ...expirationSetting,
                      days: parseInt(value, 10)
                    });
                  }}
                />
              </GridInput>
            )}
          </div>
        </CompactSwitchControlledCard>
        {getBooleanFlag(
          LaunchDarklyFlags.ENABLE_MEDIUM_RESTRICTION_ON_DISPLAY_SITES
        ) && (
          <CompactSwitchControlledCard
            labelProps={{
              value: !!publishingMediums,
              onChange: () => {
                if (publishingMediums) {
                  setPublishingMediums(undefined);
                } else {
                  setPublishingMediums([
                    PublishingMedium.Print,
                    PublishingMedium.EEdition,
                    PublishingMedium.Online
                  ]);
                }
              },
              label: 'Include specific publishing mediums',
              description:
                'Only show ads from the following publishing mediums on your site.'
            }}
          >
            <CheckboxGroup
              id="publishing-medium-selection"
              value={[
                {
                  id: PublishingMedium.Print,
                  labelText: 'Print',
                  checked: !!publishingMediums?.includes(PublishingMedium.Print)
                },
                {
                  id: PublishingMedium.EEdition,
                  labelText: 'E-Edition',
                  checked: !!publishingMediums?.includes(
                    PublishingMedium.EEdition
                  )
                },
                {
                  id: PublishingMedium.Online,
                  labelText: 'Online',
                  checked: !!publishingMediums?.includes(
                    PublishingMedium.Online
                  )
                }
              ]}
              labelText="Publishing mediums"
              onChange={newValues =>
                setPublishingMediums(
                  newValues
                    .filter(value => value.checked)
                    .map(value => value.id)
                )
              }
            />
          </CompactSwitchControlledCard>
        )}

        {enableAdditionalLinks && (
          <SiteSettingsAdditionalLinks
            value={additionalLinks}
            onChange={setAdditionalLinks}
          />
        )}

        <GridInput fullWidth>
          <ColumnButton
            primary
            type="submit"
            buttonText="Save & Setup Site"
            loading={loading}
          />
        </GridInput>
      </SwitchControlledCard>
    </Form>
  );
}
