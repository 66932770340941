import React, { Suspense, useEffect, useState } from 'react';
import { StripeProvider } from 'react-stripe-elements';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import SuspenseErrorBoundary from 'components/SuspenseErrorBoundary';
import LoadingState from 'components/LoadingState';
import GlobalToast from 'components/Toast/Toast';
import { Product } from 'lib/enums';
import { STRIPE_VARS } from './constants';
import EnoticeTheme from './Theme';
import Header from './components/Header';
import { router } from './router';

type AppProps = {
  store: any;
};

export default function App({ store }: AppProps) {
  const [stripe, setStripe] = useState(null);

  const setStripeAccount = () => {
    if ((window as any).Stripe) {
      setStripe((window as any).Stripe(STRIPE_VARS.key));
    } else {
      const script = document.querySelector('#stripe-js');
      if (script) {
        script.addEventListener('load', () => {
          setStripe((window as any).Stripe(STRIPE_VARS.key));
        });
      } else {
        console.log('Stripe not loaded correctly');
      }
    }
  };

  useEffect(() => {
    setStripeAccount();
  }, []);

  return (
    <div className="App">
      <StripeProvider stripe={stripe}>
        <Provider store={store}>
          <EnoticeTheme>
            <SuspenseErrorBoundary>
              <Suspense fallback={<LoadingState />}>
                <RouterProvider router={router} />
                <GlobalToast />
              </Suspense>
            </SuspenseErrorBoundary>
          </EnoticeTheme>
          <Header productLine={Product.Notice} />
        </Provider>
      </StripeProvider>
    </div>
  );
}
