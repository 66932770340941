import { useBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import LinkTo from 'components/LinkTo';
import { ColumnButton } from 'lib/components/ColumnButton';
import { Popover } from 'lib/components/Popover';
import { useAppSelector } from 'redux/hooks';
import { selectQuickCreateItems } from './selectOrdersTabItems';

export default function QuickCreateMenu() {
  const quickCreateItems = useAppSelector(selectQuickCreateItems);
  const enabledItems = quickCreateItems.filter(item => item.enabled);

  const enableQuickMenu = useBooleanFlag(
    LaunchDarklyFlags.ENABLE_QUICK_CREATE_MENU
  );

  if (!enableQuickMenu || enabledItems.length === 0) {
    return null;
  }

  return (
    <Popover
      id="quick-create"
      alignment="right"
      activator={
        <ColumnButton
          link
          secondary
          size="lg"
          type="button"
          buttonText="Create"
          startIcon={<PlusCircleIcon className="w-5 h-5" />}
        />
      }
    >
      <div className="w-48">
        {enabledItems.map(item => (
          <LinkTo
            key={item.id}
            href={item.path}
            className="block font-medium p-3 text-column-gray-400 hover:bg-primary-50 hover:text-primary-500 rounded-md"
          >
            {item.label}
          </LinkTo>
        ))}
      </div>
    </Popover>
  );
}
