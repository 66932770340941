/**
 * A feeSplit is used to define how much of a given rate or fee (usually paid, in absence of a feeSplit,
 * by the advertiser) is instead to be paid by the publisher or is waived. A 'full_waiver' feeSplit
 * eliminates the obligation by any party to pay for the fee, entirely.
 */

export type FeeSplit = {
  type: 'flat' | 'percent' | 'full_waiver';
  description?: string;
  /** When type is:
   * (1) 'flat', amount is the amount of the feeSplit the publisher pays in cents;
   * (2) 'percent', amount is the percentage (0-100%) of the convenienceFee that the publisher pays
   * (3) 'full_waiver, amount is meaningless (but will be zero when full_waivers are created in-app) */
  amount: number;
  /**
   * The cap is only applicable when the feeSplit type is 'percent'.
   * Cap is the maximum amount, in cents, that the payor of the fee split will pay.
   */
  cap?: number;
};

/** TrackedFeeSplitResults are stored at time of invoice creation or update under `invoice.data().feeSplits`, filed
 * to their respective feeSplit type (see the feeSplit property on the invoice type for more info).
 * These track (1) the feeSplit value that was applied to (and thereafter deducted from) the relevant Column fee amount
 * presented in the invoiceto the filer for payment, and (2) the amount in cents of such deduction, up to the max of
 * the full amount of such fee that the filer would otherwise pay.
 *
 * These are important to keep track of because these values are used to generate the corresponding Stripe invoiceItems
 * within createNoticeTransferIfNotExists which will be billed to the publisher as their payment obligation.
 */

export type TrackedFeeSplitResult = {
  feeSplit: FeeSplit;
  /** amountInCents is the amount in cents (resulting from application of the feeSplit value onto the actual Column fee
   * that otherwise the customer would pay in full) of the applicable Column fee that the publisher will be billed for
   * (the amount the publisher will pay in lieu of the filer/customer).
   * Is 0 when the feeSplit type is a 'full_waiver'; we track the amount waived under the below amountWaived property */
  amountInCents: number;
  /**
   * Note that for 'full_waiver' type feeSplits we track the full fee amount waived for forensic purposes as amountWaived (and the amountInCents is correspondingly 0),
   * even as this is not passed through to the publisher's Stripe subscription (the publisher is not billed for such amounts). */
  amountWaived?: number;
};

export type NoticeFeeSplitResults = {
  /**
   * @property convenienceFeeSplitResult is tracking two things:
   * (1) the convenience fee feeSplit object that was applied to the gross convenience fee and
   * (2) the amountInCents of the feeSplit amount deducted that was derived therefrom.
   * Appears on the invoice when there is a convenienceFeeSplit property on the applied notice rate. */
  convenienceFeeSplitResult?: TrackedFeeSplitResult;
  /**
   * @property autoAffidavitFeeSplitResult is tracking two things:
   * (1) the auto affidavit fee feeSplit object that was applied to the gross auto affidavit fee and
   * (2) the amountInCents of the feeSplit amount deducted that was derived therefrom.
   * Appears on the invoice when the ARS copied down to the notice contains an auto affidavit fee split. */
  autoAffidavitFeeSplitResult?: TrackedFeeSplitResult;
};

export type NewspaperOrderFeeSplitResults = {
  // add future additional feeSplits that may affect orders here
  convenienceFeeSplitResult: TrackedFeeSplitResult;
};

export const isFeeSplitTypeFullWaiver = (
  feeSplit: FeeSplit | undefined
): boolean => {
  return feeSplit?.type === 'full_waiver';
};
