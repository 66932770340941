export const InvoiceItemTypeData = {
  processing_fee_oop: {
    value: 1,
    label: 'Processing Fee (Out of Platform)',
    key: 'processing_fee_oop'
  },
  public_notice_refund: {
    value: 2,
    label: 'Public Notice Refund',
    key: 'public_notice_refund'
  },
  processing_fee_reversal: {
    value: 3,
    label: 'Processing Fee Reversal',
    key: 'processing_fee_reversal'
  },
  individual_invoice: {
    value: 4,
    label: 'Individual Invoice',
    key: 'individual_invoice'
  },
  individual_invoice_voided: {
    value: 5,
    label: 'Individual Invoice Voided',
    key: 'individual_invoice_voided'
  },
  individual_invoice_paid: {
    value: 6,
    label: 'Individual Invoice Paid',
    key: 'individual_invoice_paid'
  },
  individual_invoice_refunded: {
    value: 7,
    label: 'Individual Invoice Refunded',
    key: 'individual_invoice_refunded'
  },
  column_reps_fee: {
    value: 8,
    label: 'Column Reps Fee',
    key: 'column_reps_fee'
  },
  convenience_fee_split: {
    value: 9,
    label: 'Convenience Fee Split',
    key: 'convenience_fee_split'
  },
  auto_affidavit_fee_split: {
    value: 10,
    label: 'Auto Affidavit Fee Split',
    key: 'auto_affidavit_fee_split'
  },
  additional_fee_split: {
    value: 11,
    label: 'Additional Fee Split',
    key: 'additional_fee_split'
  },
  design_fee: {
    value: 12,
    label: 'Design Fee',
    key: 'design_fee'
  },
  placement_fee: {
    value: 13,
    label: 'Express Fee',
    key: 'express_fee'
  },
  placement_fee_refund: {
    value: 14,
    label: 'Express Fee Refund',
    key: 'express_fee_refund'
  },
  orders_convenience_fee_split: {
    value: 15,
    label: 'Orders Convenience Fee Split',
    key: 'orders_convenience_fee_split'
  }
} as const;

export type InvoiceItemType = typeof InvoiceItemTypeData;

export type InvoiceItemValue = InvoiceItemType[keyof InvoiceItemType]['value'];
export type InvoiceItemKey = InvoiceItemType[keyof InvoiceItemType]['key'];
