import React, { useContext, useState } from 'react';
import { NewspaperOrder } from 'lib/types/newspaperOrder';
import { FilingTypeVisibility, Product } from 'lib/enums';
import { OrderEditableData, OrderModel } from 'lib/model/objects/orderModel';
import { FilingTypeModel } from 'lib/model/objects/filingTypeModel';
import useSafeAsyncEffect from 'lib/frontend/hooks/useSafeAsyncEffect';
import { ProductPublishingSettingsService } from 'lib/services/productPublishingSettingsService';
import { getFirebaseContext } from 'utils/firebase';
import { ResponseOrError, wrapError, wrapSuccess } from 'lib/types/responses';
import { safeStringify } from 'lib/utils/stringify';
import { useAppSelector } from 'redux/hooks';
import { selectIsPublisher } from 'redux/auth';
import LoadingState from 'components/LoadingState';
import { ColumnService } from 'lib/services/directory';
import { DetailedProductPublishingSetting } from 'lib/types/publishingSetting';
import { PublishingMedium } from 'lib/enums/PublishingMedium';
import { asyncMap, isDefined } from 'lib/helpers';
import { Alert } from 'lib/components/Alert';
import { logAndCaptureException } from 'utils';
import { EOrganization, ESnapshotExists } from 'lib/types';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import PerPublisherSettings from './PerPublisherSettings';
import { NewspaperOrdersFormData } from '../../PlacementFlowStepSelector';
import { NewspaperOrderWithPublishingSettings } from './validation';
import { NewspapersContext } from '../../contexts/NewspapersContext';
import { calculateDefaultPublishingDates } from './orderPublishingDateCalculation';
import MultiStepHeader from '../../components/MultiStepHeader';
import Placemat from '../../Placemat';

const isValidFilingType = (
  filingType: FilingTypeModel,
  isPublisher: boolean
): boolean => {
  // Filing type must have a rate
  if (!filingType.modelData.rate) {
    return false;
  }

  // Filing type must not be disabled
  if (filingType.modelData.visibility === FilingTypeVisibility.disabled.value) {
    return false;
  }

  if (
    !isPublisher &&
    filingType.modelData.visibility ===
      FilingTypeVisibility.publisher_only.value
  ) {
    return false;
  }

  return true;
};

const getValidFilingTypes = (
  filingTypes: FilingTypeModel[] | undefined,
  isPublisher: boolean
): FilingTypeModel[] | undefined =>
  filingTypes?.filter(filingType => isValidFilingType(filingType, isPublisher));

const getNewspaperOrderWithPublishingSettings = async (
  product: Product,
  isPublisher: boolean,
  newspaperOrder: Partial<NewspaperOrder>,
  newspapers: ESnapshotExists<EOrganization>[]
): Promise<ResponseOrError<NewspaperOrderWithPublishingSettings>> => {
  const newspaperRef = newspaperOrder.newspaper;

  if (!newspaperRef) {
    const error = new Error('Newspaper order missing newspaper');

    return wrapError(error);
  }

  const newspaperSnap = newspapers.find(o => o.id === newspaperRef.id);

  if (!newspaperSnap) {
    const error = new Error('Newspaper context missing newspaper');

    return wrapError(error);
  }

  const productPublishingSettingService = new ProductPublishingSettingsService(
    getFirebaseContext()
  );

  const {
    response: detailedPublishingSettings,
    error: publishingSettingsError
  } =
    await productPublishingSettingService.fetchOrCreateDetailedProductPublishingSetting(
      newspaperOrder.newspaper,
      product,
      newspaperOrder.publishingMedium,
      {
        shouldCreate: false
      }
    );

  if (publishingSettingsError) {
    return wrapError(publishingSettingsError);
  }

  const { filingTypes: allFilingTypes } = detailedPublishingSettings;

  const publishingSettings: DetailedProductPublishingSetting = {
    ...detailedPublishingSettings,
    filingTypes: getValidFilingTypes(allFilingTypes, isPublisher) || []
  };
  const selectedFilingTypeId = newspaperOrder.filingType?.id;
  const filingType = publishingSettings.filingTypes.find(
    ft => ft.id === selectedFilingTypeId
  );

  const publishingDates = newspaperOrder.publishingDates?.length
    ? newspaperOrder.publishingDates
    : calculateDefaultPublishingDates({
        newspaperSnap,
        publishingSetting: publishingSettings.publishingSetting,
        filingType
      });

  const newspaperOrderWithDates: NewspaperOrderWithPublishingSettings['newspaperOrder'] =
    {
      ...newspaperOrder,
      newspaper: newspaperRef,
      publishingDates
    };

  return wrapSuccess({
    newspaperOrder: newspaperOrderWithDates,
    publishingSettings
  });
};

type SelectSchedulesProps = {
  newspaperOrdersFormData: NewspaperOrdersFormData;
  onNewspaperOrdersFormDataChange: React.Dispatch<
    React.SetStateAction<NewspaperOrdersFormData>
  >;
  product: Product;
  isInitialPlacementFlow: boolean;
  editData: OrderEditableData | undefined;
  orderModel: OrderModel;
};

function SelectSchedules({
  newspaperOrdersFormData,
  onNewspaperOrdersFormDataChange,
  product,
  isInitialPlacementFlow,
  editData,
  orderModel
}: SelectSchedulesProps) {
  const { publishersAvailableForPlacement } = useContext(NewspapersContext);
  const isPublisher = useAppSelector(selectIsPublisher);
  const [userError, setUserError] = useState('');

  const {
    value: publishingSettingsForNewspaperOrders,
    isLoading: publishingSettingsLoading
  } = useSafeAsyncEffect({
    fetchData: async () => {
      const result = await asyncMap(newspaperOrdersFormData, newspaperOrder =>
        getNewspaperOrderWithPublishingSettings(
          product,
          isPublisher,
          newspaperOrder,
          publishersAvailableForPlacement
        )
      );

      if (result.error) {
        setUserError(result.error.message);
        logAndCaptureException(
          ColumnService.ORDER_PLACEMENT,
          result.error,
          result.error.message,
          {
            newspaperOrders: `${newspaperOrdersFormData
              .map(nofd => ({
                newspaperId: nofd.newspaper?.id,
                orderStatus: nofd.status,
                filingType: nofd.filingType?.id,
                medium: nofd.publishingMedium
              }))
              .join(', ')}`,
            orderId: orderModel.id
          }
        );
        return result;
      }

      const record: Record<
        string,
        Partial<Record<PublishingMedium, DetailedProductPublishingSetting>>
      > = {};

      result.response.forEach(({ newspaperOrder, publishingSettings }) => {
        if (!newspaperOrder.publishingMedium || !newspaperOrder.newspaper) {
          const error = new Error('Missing required newspaper order data');

          setUserError(error.message);

          logAndCaptureException(
            ColumnService.ORDER_PLACEMENT,
            error,
            error.message,
            {
              publishingMedium: `${newspaperOrder.publishingMedium}`,
              newspaperId: `${newspaperOrder.newspaper.id}`,
              orderId: orderModel.id
            }
          );

          return;
        }

        record[newspaperOrder.newspaper.id] = {
          ...record[newspaperOrder.newspaper.id],
          [newspaperOrder.publishingMedium]: publishingSettings
        };
      });

      onNewspaperOrdersFormDataChange(
        result.response.map(o => o.newspaperOrder)
      );

      return wrapSuccess(record);
    },
    initialData: null,
    dependencies: [
      safeStringify(
        newspaperOrdersFormData.map(
          no => `${(no.newspaper?.id, no.publishingMedium)}`
        )
      )
    ]
  });

  const { value: disableChangeNumberOfPublicationDates } = useAsyncEffect({
    fetchData: async () => {
      const [failedToGetInvoice, invoice] = await orderModel.getInvoice();

      if (failedToGetInvoice) {
        setUserError(failedToGetInvoice.message);
        logAndCaptureException(
          ColumnService.ORDER_PLACEMENT,
          failedToGetInvoice,
          'Error getting invoice from order'
        );
        return;
      }

      return wrapSuccess(!!invoice?.isPaidDirectToPubisher());
    },
    dependencies: [orderModel.id]
  });

  if (userError) {
    return <Alert id="newspaper-error" title="Error" description={userError} />;
  }

  if (publishingSettingsLoading || !publishingSettingsForNewspaperOrders) {
    return (
      <LoadingState
        context={{
          service: ColumnService.ORDER_PLACEMENT,
          location: 'Ad placement - Select Schedules',
          tags: {
            product,
            adPlacementFlow: 'true',
            publishingSettingsLoading: publishingSettingsLoading
              ? 'true'
              : 'false',
            newspaperOrdersWithPublishingSettings:
              publishingSettingsForNewspaperOrders ? 'available' : 'null',
            orderId: orderModel.id
          }
        }}
      />
    );
  }

  const newspaperOrders: NewspaperOrderWithPublishingSettings['newspaperOrder'][] =
    newspaperOrdersFormData
      .map(no => {
        const newspaperSnap = publishersAvailableForPlacement.find(
          n => n.id === no.newspaper?.id
        );

        if (!newspaperSnap) {
          const error = new Error(
            'Newspaper order contains newspaper not in context'
          );

          logAndCaptureException(
            ColumnService.ORDER_PLACEMENT,
            error,
            error.message,
            {
              newspaperId: `${no.newspaper?.id}`,
              orderId: orderModel.id
            }
          );

          logAndCaptureException(
            ColumnService.ORDER_PLACEMENT,
            error,
            error.message,
            {
              newspaperId: `${no.newspaper?.id}`,
              orderId: orderModel.id
            }
          );

          return;
        }

        if (!no.publishingDates?.length) {
          const error = new Error('Missing publishing dates');

          logAndCaptureException(
            ColumnService.ORDER_PLACEMENT,
            error,
            error.message,
            {
              newspaperId: `${newspaperSnap.id}`,
              publishingMedium: no.publishingMedium,
              orderId: orderModel.id
            }
          );

          return;
        }

        return {
          ...no,
          newspaper: newspaperSnap.ref,
          publishingDates: no.publishingDates
        };
      })
      .filter(isDefined);

  const onNewspaperOrderChange = (newspaperOrder: Partial<NewspaperOrder>) => {
    onNewspaperOrdersFormDataChange(
      newspaperOrders.map(o =>
        o.newspaper.id === newspaperOrder.newspaper?.id &&
        o.publishingMedium === newspaperOrder.publishingMedium
          ? { ...o, ...newspaperOrder }
          : o
      )
    );
  };

  const editableNewspaperIds = Object.values(editData || {})
    .filter(ed => ed.canEdit)
    .map(ed => ed.newspaperId);

  const canEditNewspaperOrder = (newspaperOrder: Partial<NewspaperOrder>) => {
    if (isInitialPlacementFlow) {
      return true;
    }

    return editableNewspaperIds.some(
      newspaperId => newspaperId === newspaperOrder.newspaper?.id
    );
  };

  const newspaperOrdersWithPublishingSettings = newspaperOrders
    .map(o => ({
      newspaperOrder: o,
      publishingSettings:
        o.newspaper &&
        o.publishingMedium &&
        publishingSettingsForNewspaperOrders[o.newspaper.id][o.publishingMedium]
    }))
    .filter<NewspaperOrderWithPublishingSettings>(
      (o): o is NewspaperOrderWithPublishingSettings =>
        isDefined(o.publishingSettings)
    );

  return (
    <Placemat>
      <MultiStepHeader
        title={`Publication dates`}
        description={`Choose preferred publication dates for your order`}
      />
      <div className="flex flex-col gap-8">
        {newspaperOrdersWithPublishingSettings.map(
          newspaperOrderWithPublishingSettings => (
            <PerPublisherSettings
              key={
                newspaperOrderWithPublishingSettings.publishingSettings
                  .publishingSetting.id
              }
              product={product}
              newspaperOrderWithPublishingSettings={
                newspaperOrderWithPublishingSettings
              }
              onNewspaperOrderChange={onNewspaperOrderChange}
              disableEdits={
                !canEditNewspaperOrder(
                  newspaperOrderWithPublishingSettings.newspaperOrder
                )
              }
              disableChangeNumberOfPublicationDates={
                !!disableChangeNumberOfPublicationDates
              }
            />
          )
        )}
      </div>
    </Placemat>
  );
}

export default SelectSchedules;
