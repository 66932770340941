import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  EFirebaseContext,
  ESnapshotExists,
  EJoinRequest,
  EUser
} from 'lib/types';
import AuthActions, { selectUserAuth } from 'redux/auth';
import { OccupationType, InviteStatus, State } from 'lib/enums';
import AdvertiserRegistrationFooter from 'routes/register/AdvertiserRegistrationFooter';
import api from 'api';
import { getFirebaseContext } from 'utils/firebase';
import { SelectedOrganizationItem } from 'routes/register/types';
import { logAndCaptureException } from 'utils';
import { getCreateCustomerFn } from 'utils/callableFunctions';
import { updatePendingInviteStatusForAnonUsers } from 'routes/register/helpers';
import { REGISTER_ROUTES } from 'router/routes';
import { getRedirect } from 'utils/urls';
import { TextField } from 'lib/components/TextField';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { Autocomplete } from 'lib/components/Autocomplete';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import { ColumnService } from 'lib/services/directory';
import * as validators from '../organization/validators';

export const sendOrganizationInvites = async (
  selectedOrganizations: SelectedOrganizationItem[],
  user: ESnapshotExists<EUser>,
  ctx: EFirebaseContext
) => {
  for (const item of selectedOrganizations) {
    const requestObj: EJoinRequest = {
      createdAt: getFirebaseContext().fieldValue().serverTimestamp() as any,
      email: user.data().email,
      userId: user.id,
      organization: getFirebaseContext().organizationsRef().doc(item.value),
      status: InviteStatus.pending.value
    };
    // eslint-disable-next-line no-await-in-loop
    await ctx.joinRequestsRef().add(requestObj);
  }
};

export const notifyOrganizationAdminUsers = async (
  user: ESnapshotExists<EUser>
) => {
  try {
    const userRequests = await getFirebaseContext()
      .joinRequestsRef()
      .where('email', '==', user.data().email)
      .get();
    if (!userRequests.docs.length) return;
    await api.post('users/send-requests');
  } catch (err) {
    logAndCaptureException(
      ColumnService.AUTH_AND_USER_MANAGEMENT,
      err,
      'Error on sending joining requests email to admin users',
      {
        userId: user.id
      }
    );
  }
};

export const isFieldNonEmpty = (field: string) => {
  return field.trim().length > 0;
};

export default function RegisterIndividualForm({
  user
}: {
  user: ESnapshotExists<EUser>;
}) {
  const navigate = useNavigate();
  const userAuth = useAppSelector(selectUserAuth);
  const dispatch = useAppDispatch();

  const [addressLine1, setAddressLine1] = useState(user.data().address || '');
  const [addressLine2, setAddressLine2] = useState(
    user.data().addressLine2 || ''
  );
  const [city, setCity] = useState(user.data().city || '');
  const [state, setState] = useState(user.data().state);
  const [zipCode, setZipCode] = useState(user.data().zipCode || '');
  const [phone, setPhone] = useState(user.data().phone || '');

  // We have separate variables for when we actually want to display an error if a field
  // is not valid because we do not want them displaying for blank fields when the user
  // gets to the address step of the registration flow.
  const isAddressLine1Valid =
    isFieldNonEmpty(addressLine1) && validators.addressField(addressLine1);
  const isAddressLine1ValidDisplayError = isAddressLine1Valid || !addressLine1;
  const isAddressLine2Valid =
    !addressLine2 || validators.addressField(addressLine2);
  const isCityValid = isFieldNonEmpty(city) && validators.addressField(city);
  const isCityValidDisplayError = isCityValid || !city;
  const isZipValid = validators.zipAdvertiser(zipCode);
  const isPhoneValid = validators.phoneRegex.test(phone);
  const [loading, setLoading] = useState(false);

  const ctx = getFirebaseContext();

  const disable =
    !isAddressLine1Valid ||
    !isAddressLine2Valid ||
    !isCityValid ||
    !state ||
    !isZipValid ||
    !isPhoneValid;

  const onBackClick = () => {
    navigate(REGISTER_ROUTES.OCCUPATIONS);
  };

  const handleSubmit = async () => {
    if (disable || !userAuth) {
      return;
    }

    setLoading(true);

    // Create stripe customer id for individual
    const createCustomer = getCreateCustomerFn();

    // If a newspaper has already invoiced this user pre-registration,
    // the user will have an existing stripe ID
    let result;
    let newStripeId: string | undefined;
    if (!user.data().stripeId) {
      result = await createCustomer({
        name: user.data().name,
        email: user.data().email,
        firestoreUID: userAuth.uid
      });

      if (!result) {
        console.error('Unable to create new Stripe customer ID');
        return;
      }

      newStripeId = result.data.stripeId;
    }

    const userUpdateObject: Partial<EUser> = {
      occupation: OccupationType.individual.value,
      address: addressLine1,
      addressLine2: addressLine2 || '',
      city,
      state,
      zipCode,
      phone,
      stripeId: user.data().stripeId || newStripeId,
      postRegistrationComplete: true
    };

    await ctx.usersRef().doc(userAuth.uid).update(userUpdateObject);

    // Confusing naming alert:
    // The below auth action doesn't actually register - it populates
    // user session information
    dispatch(AuthActions.register());

    await notifyOrganizationAdminUsers(user);
    /* Updating status of penging invites to accepted at the end because we are
    handling the routing of registration flow based on the pending invites (member invited by an organization) in routing saga */
    await updatePendingInviteStatusForAnonUsers(ctx, user);

    navigate(getRedirect());
  };

  const nextButtonClicked = async () => {
    await handleSubmit();
  };

  return (
    <>
      <CardGridLayout>
        <GridInput>
          <TextField
            id={'address-line-1'}
            required
            value={addressLine1}
            onChange={setAddressLine1}
            placeholder={'Address line 1'}
            labelText={'Address line 1'}
            errorText={
              isAddressLine1ValidDisplayError ? '' : 'Address must be valid.'
            }
          />
        </GridInput>
        <GridInput>
          <TextField
            id={'address-line-2'}
            value={addressLine2}
            onChange={setAddressLine2}
            placeholder={'Address line 2'}
            labelText={'Address line 2'}
            errorText={isAddressLine2Valid ? '' : 'Address must be valid.'}
          />
        </GridInput>
        <GridInput>
          <TextField
            id={'city'}
            required
            value={city}
            onChange={setCity}
            placeholder={'City'}
            labelText={'City'}
            errorText={isCityValidDisplayError ? '' : 'City must be valid.'}
          />
        </GridInput>
        <GridInput>
          <Autocomplete
            id={'state'}
            required
            value={state?.toString()}
            options={State.items().map(item => ({
              value: item.value.toString(),
              label: item.label
            }))}
            onChange={newState => setState(parseInt(newState, 10))}
            placeholder={'State'}
            labelText={'State'}
          />
        </GridInput>
        <GridInput>
          <TextField
            id={'phone'}
            required
            type={'tel'}
            value={phone}
            placeholder={'Phone'}
            onChange={setPhone}
            labelText="Phone number"
          />
        </GridInput>
        <GridInput>
          <TextField
            id={'zipCode'}
            required
            type={'postal-code'}
            value={zipCode}
            placeholder={'Zip Code'}
            onChange={setZipCode}
            labelText="Zip Code"
          />
        </GridInput>
      </CardGridLayout>
      <div className="px-6">
        <AdvertiserRegistrationFooter
          id="submit-individual"
          backButtonText="Go back"
          nextButtonText="Create account"
          onBackButtonClick={onBackClick}
          onNextButtonClick={nextButtonClicked}
          loading={loading}
          disableNextButton={disable || loading}
        />
      </div>
    </>
  );
}
