import { useState } from 'react';
import classNames from 'classnames';
import LoadingState from 'components/LoadingState';
import { MediaModel } from 'lib/model/objects/mediaModel';
import { ColumnService } from 'lib/services/directory';
import { Alert } from 'lib/components/Alert';
import { Media } from 'lib/types';
import ClipArt from './ClipArt';
import { useMediaLibrary } from './clipArtHelpers';
import MediaEditorModal from './MediaEditorModal';

type ClipArtLibraryProps = {
  organizationId: string;
  folder: Media['folder'];
  uploads?: string[];
};

function ClipArtLibrary({
  organizationId,
  folder,
  uploads
}: ClipArtLibraryProps) {
  const [selectedMedia, setSelectedMedia] = useState<MediaModel | null>(null);
  const {
    value: mediaList,
    isLoading,
    isError,
    invalidateData
  } = useMediaLibrary({ organizationId, folder, uploads });

  if (isError) {
    return (
      <Alert
        id="clip-art-error"
        title="Loading failure"
        description="Failed to load clip art library. If the error persists, please contact Column support."
        status="error"
      />
    );
  }

  if (isLoading) {
    return (
      <LoadingState
        context={{
          location: window.location.pathname,
          tags: { organizationId },
          service: ColumnService.FILE_STORAGE
        }}
      />
    );
  }

  return (
    <>
      <div
        className={classNames('grid md:grid-cols-3 overflow-auto gap-1 p-1', {
          border: mediaList?.length
        })}
        style={{ maxHeight: mediaList?.length ? '40vh' : 0 }}
      >
        {mediaList?.map(media => (
          <ClipArt
            key={media.modelData.cdnUrl}
            src={media.modelData.cdnUrl}
            onSelect={() => {
              setSelectedMedia(media);
            }}
          />
        ))}
      </div>
      {selectedMedia && (
        <MediaEditorModal
          media={selectedMedia}
          onClose={() => setSelectedMedia(null)}
          onEdit={invalidateData}
        />
      )}
    </>
  );
}

export default ClipArtLibrary;
