import { useEffect, useState } from 'react';
import { getFirebaseContext } from 'utils/firebase';
import { PublicationIssueSectionSearchRequest } from 'lib/types/publicationIssue';
import {
  queryPublicationIssues,
  queryPublicationIssuesAndSections
} from 'lib/services/publicationIssueService';
import { PublicationIssueWithSection } from 'lib/types/publicationIssueSection';
import { PublicationIssueModel } from 'lib/model/objects/publicationIssueModel';
import { safeGetOrThrow } from 'lib/safeWrappers';
import moment from 'moment';
import {
  PaginationTabs,
  comparePublicationIssuesSort
} from '../paginationTableUtils';

// TODO: This is horrendous! We need to do aggregation in elastic, not firebase
export const SEATON_PAGINATION_ID = 'yX5JPInqQXVKHTTM05qN';
export const FCC_PAGINATION_ID = 'xVWmRsNEh9zobzmevsrr';
const PUBLISHER_ISSUES_LIMIT: Record<string, number> = {
  [SEATON_PAGINATION_ID]: 7,
  [FCC_PAGINATION_ID]: 30
};

/**
 * This is a temporary function to limit the number of issues we query for.
 * We need to do aggregation in elastic, not firebase
 */
export const getSubsetIssuesForQuery = async (
  publicationIssues: PublicationIssueModel[]
) => {
  const [firstIssue] = publicationIssues;
  if (!firstIssue) {
    return publicationIssues;
  }
  const [publisherError, publisher] = await safeGetOrThrow(
    firstIssue.modelData.publisher
  );
  if (publisherError || !publisher) {
    return publicationIssues;
  }

  const dayRestriction =
    PUBLISHER_ISSUES_LIMIT[publisher.data().parent?.id || publisher.id || ''];

  if (!dayRestriction) {
    return publicationIssues;
  }
  const filteredIssues = publicationIssues.filter(
    issue =>
      Math.abs(
        moment(issue.modelData.publicationDate, 'YYYY-MM-DD').diff(
          moment(),
          'days'
        )
      ) <= dayRestriction
  );

  return filteredIssues;
};

export function useGetPublicationIssues(
  query: PublicationIssueSectionSearchRequest,
  refreshTimestamp: number,
  paginationTab: PaginationTabs
) {
  const [publicationIssuesWithSections, setPublicationIssuesWithSections] =
    useState<PublicationIssueWithSection[]>([]);
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchPublicationIssues = async () => {
      setLoading(true);
      if (!query.publisherIds.length) {
        return;
      }
      const {
        response: publicationIssues,
        error: queryPublicationIssuesError
      } = await queryPublicationIssues(getFirebaseContext(), query);
      if (queryPublicationIssuesError) {
        return setError(queryPublicationIssuesError.message);
      }

      const subsetIssuesForQuery = await getSubsetIssuesForQuery(
        publicationIssues
      );
      const {
        response: publicationIssuesWithSections,
        error: queryPublicationIssuesWithSectionsError
      } = await queryPublicationIssuesAndSections(subsetIssuesForQuery, query);
      if (queryPublicationIssuesWithSectionsError) {
        return setError(queryPublicationIssuesWithSectionsError.message);
      }

      setPublicationIssuesWithSections(publicationIssuesWithSections);
      setLoading(false);
    };
    void fetchPublicationIssues();
  }, [query, refreshTimestamp]);

  const sortedPublicationIssuesWithSections =
    publicationIssuesWithSections.sort((a, b) =>
      comparePublicationIssuesSort(a, b, paginationTab)
    );

  return {
    loading,
    error,
    publicationIssuesWithSections: sortedPublicationIssuesWithSections
  };
}
