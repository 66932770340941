import { FirebaseUploadTaskSnapshot, exists } from 'lib/types';
import { Form } from 'lib/components/Form';
import UploadButton from 'components/UploadButton';
import LoadingState from 'components/LoadingState';
import { ColumnButton } from 'lib/components/ColumnButton';
import CardWithHeader from 'lib/components/Card/CardWithHeader';
import { XCircleIcon } from '@heroicons/react/24/outline';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import ToastActions from 'redux/toast';
import { logError } from 'utils/logger';
import { selectActiveOrganization, selectUser } from 'redux/auth';
import { useState } from 'react';
import { getModelFromSnapshot } from 'lib/model';
import { OrganizationModel } from 'lib/model/objects/organizationModel';
import { getFirebaseContext } from 'utils/firebase';
import SettingsHeader from './SettingsHeader';

function CustomBrandSettings() {
  const dispatch = useAppDispatch();
  const activeOrganization = useAppSelector(selectActiveOrganization);
  const user = useAppSelector(selectUser);
  const [icon, setIcon] = useState<string>();

  if (!exists(user) || !exists(activeOrganization)) {
    return <LoadingState />;
  }

  const organization = getModelFromSnapshot(
    OrganizationModel,
    getFirebaseContext(),
    activeOrganization
  );

  const description = organization.isPublisherOrganization
    ? 'This logo will appear on your public-facing search page and emails sent to clients through Column.'
    : 'If the publisher you’re placing an obituary in has enabled funeral home logos, then the logo uploaded here will be added to the proof. Not all publishers in Column have this feature enabled. During placement you will be able to remove your logo if you don’t want to include it on the obituary proof.';
  const showDeleteButton = !organization.isPublisherOrganization;

  const handleDelete = async () => {
    try {
      await activeOrganization?.ref.update({
        icon: '',
        darkLogo: ''
      });

      dispatch(
        ToastActions.toastSuccess({
          headerText: 'Success',
          bodyText: 'Icon removed successfully'
        })
      );
    } catch (error) {
      logError('Failed to delete organization icon', {
        activeOrganization: activeOrganization?.id,
        user: user.id
      });
      dispatch(
        ToastActions.toastError({
          headerText: 'Error',
          bodyText: 'Failed to remove icon'
        })
      );
    }
  };

  return (
    <div className="bg-white rounded-lg border border-gray-300 shadow">
      <Form
        id="branding-settings-table"
        onSubmit={async () => {
          try {
            if (icon) {
              await activeOrganization?.ref.update({
                // The logo that shows during restricted placement flow
                icon,
                // The logo that shows on the display site
                darkLogo: icon
              });
            }
            dispatch(
              ToastActions.toastSuccess({
                headerText: 'Success',
                bodyText: 'Update successful'
              })
            );
          } catch (error) {
            logError('Failed to update organization icon', {
              activeOrganization: activeOrganization?.id,
              user: user.id
            });
            dispatch(
              ToastActions.toastError({
                headerText: 'Error',
                bodyText: 'Update failed'
              })
            );
          }
        }}
      >
        <SettingsHeader
          header="Custom Branding"
          description="Configure your branding."
        >
          <ColumnButton primary type="submit" buttonText="Save" />
        </SettingsHeader>
        <CardWithHeader
          header={{
            title: 'Organization Logo',
            description
          }}
        >
          <div className="flex flex-row items-center">
            <div className="flex items-center gap-4">
              {icon && (
                <img
                  style={{ maxHeight: 80, maxWidth: 100 }}
                  src={icon}
                  alt="icon"
                />
              )}
              <UploadButton
                folder={`documentcloud/${activeOrganization?.id}/icons/`}
                accept="image/x-png,image/gif,image/jpeg"
                label={icon ? 'Update Icon' : 'Choose Icon'}
                processUpload={async (file: FirebaseUploadTaskSnapshot) =>
                  setIcon(await file.ref.getDownloadURL())
                }
              />
              {showDeleteButton && (
                <ColumnButton
                  type="button"
                  onClick={handleDelete}
                  buttonText="Remove Icon"
                  startIcon={<XCircleIcon className="h-5 w-5" />}
                />
              )}
            </div>
          </div>
        </CardWithHeader>
      </Form>
    </div>
  );
}

export default CustomBrandSettings;
