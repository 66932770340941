import {
  EOrganization as Organization,
  XMLSyncExportSettings as EXMLSyncExportSettings,
  BuildIntegrationExportSettings as EBuildIntegrationExportSettings,
  BulkDownloadNoticeGrouping as IBulkDownloadNoticeGrouping
} from './organization';
import {
  ESnapshot as Snapshot,
  ERef as Ref,
  EQuerySnapshot as QuerySnapshot,
  ESnapshotExists as SnapshotExists,
  EUnsubscribe as Unsubscribe,
  ECollectionRef as CollectionRef,
  ERefFactory as RefFactory,
  EQuery as Query,
  EFirebaseContext as FirebaseContext,
  ESignInProvider as SignInProvider,
  EBulkWriter as BulkWriter,
  ETransaction as Transaction,
  EFieldValue as FieldValue,
  EFieldValues as FieldValues,
  EDocumentData as DocumentData,
  EPartialDocumentData as PartialDocumentData,
  EInherits as Inherits
} from './firebase';

import {
  EUser as User,
  EUserNotificationType as UserNotificationType,
  EUserNotificationBaseType as UserNotificationBaseType,
  EUserSavedInfo as UserSavedInfo
} from './user';

import { ESource as Source } from './source';

import { EInvite as Invite } from './invite';

import { Notarization as ColumnNotarization } from './notarization';

import * as CustomerOrganizationTypes from './customerOrganization';
import * as CustomerTypes from './customer';
import * as EventTypes from './events';
import { ErrorReporter as IErrorReporter } from './errors';

import {
  ENotice as Notice,
  DisplayParams,
  Crop,
  ENoticeDraft as Draft,
  EPreviewNotice as PreviewNotice,
  ENoticeFile as NoticeFile,
  ENoticeFileNoticeContent as NoticeFileNoticeContent,
  ENoticeFileSupplementalContent as NoticeFileSupplementalContent,
  ENoticeFileLegacySchema as NoticeFileLegacySchema
} from './notice';

import { EmailConfirmation as IEmailConfirmation } from './email_confirmation';

import {
  InviteEmailsRequest as IEmailsRequest,
  BulkDownloadResponse as IBulkResponse,
  NotFoundReponse as INotFoundResponse,
  BulkDownloadRequest as IBulkDownloadRequest,
  DisplayImageRequest as IDisplayImageRequest
} from './requests';

import {
  convertAPIResponse as IConvertAPIResponse,
  TemplateSpecs as ITemplateSpecs
} from './responses';

import { EPublicNotice as PublicNotice } from './publicNotice';

import { MailDelivery as Mail } from './mail';
import { EAddress as Address, EPayToAddress as PayToAddress } from './address';

import {
  ENotificationSettings as NotificationSettings,
  ENotification as Notification,
  Attachment
} from './notifications';

import { ERate as Rate } from './rates';

import {
  EInvoice as Invoice,
  EPaywayInvoice as PaywayInvoice,
  LineItem as InvoiceLineItem,
  EInAppLineItem as InAppLineItem,
  EInvoiceRecipient as InvoiceRecipient,
  EInvoiceRecipientEmail as InvoiceRecipientEmail,
  EInvoiceRecipientMail as InvoiceRecipientMail
} from './invoices';

import {
  ETemplate as Template,
  ETemplateStyles as TemplateStyles
} from './templates';

import { ETransfer as Transfer } from './transfers';
import { EUploadedFileAttr as UploadFileAttrs } from './bulk';

import {
  PDFPageNDisplay as IPDFPageNDisplay,
  PDFPageNLiner as IPDFPageNLiner,
  PDFPageN as IPDFPageN,
  RenderedPageN as IRenderedPageN
} from './pdfs';

import { ESubscription as Subscription } from './subscription';

import {
  EStripeBalanceTransaction as StripeBalanceTransaction,
  EStripeCustomer as StripeCustomer,
  EStripeCustomerBalanceTransaction as StripeCustomerBalanceTransaction,
  EStripeConfirmation as StripeConfirmation,
  EStripeSource as StripeSource,
  EStripeBankAccount as StripeBankAccount,
  EStripeUsageRecord as StripeUsageRecord,
  EStripeSubscriptionItem as StripeSubscriptionItem,
  EStripePayout as StripePayout,
  EPayoutData as PayoutData,
  EStripePaymentMethod as StripePaymentMethod,
  EStripePaymentMethodCard as StripePaymentMethodCard,
  EStripePaymentMethodUSBankAccount as StripePaymentMethodUSBankAccount,
  EStripeTransfer as StripeTransfer
} from './stripe';

import {
  EDwollaTransfer as DwollaTransfer,
  EDwollaFundingSource as DwollaFundingSource
} from './dwolla';

import { ELabel as Label } from './label';
import { CardInvoice, CardInvoiceLineItem } from './cardInvoice';
import { Card } from './card';
import { CardTransaction } from './cardTransactions';
import { EInvoiceItem as InvoiceItem } from './invoiceItem';
import { EPayout as Payout } from './payout';
import { EIntegrationOptions, EIntegrationTrigger } from './integrations';

import {
  EResponseTypes as ResponseTypes,
  ERequestTypes as RequestTypes
} from './api';

import {
  EDisplaySite as DisplaySite,
  EUploadID as UploadID,
  OrganizationUploadID as EOrganizationUploadID,
  DisplaySiteSubdomain as EDisplaySiteSubdomain
} from './displaySite';
import {
  LaunchDarklyFlags as ELaunchDarklyFlags,
  FeatureFlagOptions as EFeatureFlagOptions,
  LaunchDarklyContext as ELaunchDarklyContext
} from './launchDarklyFlags';
import { EMigration as Migration } from './migrations';
import { EDeadline as Deadline } from './deadlines';
import {
  ExternalUploadSettings as EExternalUploadSettings,
  ExternalUploadConfig
} from './externalUploads';
import { EJoinRequest as JoinRequest } from './joinRequest';
import { EData as Data } from './data';
import { FtpFile } from './ftpFile';
import { UpdateSettingRequest } from './updateSettingsRequest';
import { AdjudicationArea } from './adjudicationArea';

export * from './notes';

export type { AffidavitTemplate } from './affidavitTemplate';

export type { LedgerItem } from './ledger';

export type EResponseTypes = ResponseTypes;
export type ERequestTypes = RequestTypes;
export type Notarization = ColumnNotarization;
export type Event = EventTypes.Event;
export type NoticeSyncEvent = EventTypes.NoticeSyncEvent;
export type NoticeSyncResponse = EventTypes.NoticeSyncResponse;
export type NoticeNewspaperRequested = EventTypes.NoticeNewspaperRequested;
export type NoticeSubmittedEvent = EventTypes.NoticeSubmittedEvent;
export type InvoiceMailSentEvent = EventTypes.InvoiceMailSentEvent;
export type NoticeCancelledEvent = EventTypes.NoticeCancelledEvent;
export type NoticeCancellationRequestedEvent =
  EventTypes.NoticeCancellationRequestedEvent;
export type NoticeCancelledAndRefundedEvent =
  EventTypes.NoticeCancelledAndRefundedEvent;
export type NoticeEditedEvent = EventTypes.NoticeEditedEvent;
export type NoticeConfirmedEvent = EventTypes.NoticeConfirmedEvent;
export type NoticeSyncFailure = EventTypes.NoticeSyncFailure;
export type NoticeCreateEvent = EventTypes.NoticeCreatedEvent;

export type InvoiceCreateEvent = EventTypes.InvoiceCreateEvent;
export type InvoiceCancelledEvent = EventTypes.InvoiceCancelledEvent;
export type InvoiceRefundedEvent = EventTypes.InvoiceRefundedEvent;
export type InvoiceReCreatedEvent = EventTypes.InvoiceReCreatedEvent;
export type InvoiceReminderSentEvent = EventTypes.InvoiceReminderSentEvent;
export type InvoicePaymentProcessEvent = EventTypes.InvoicePaymentProcessEvent;
export type InvoicePaymentProcessedManualEvent =
  EventTypes.InvoicePaymentProcessedManualEvent;
export type InvoicePaymentNoteEvent = EventTypes.InvoicePaymentNoteEvent;
export type InvoicePaymentInitiatedEvent =
  EventTypes.InvoicePaymentInitiatedEvent;
export type InvoiceUpfrontPaymentWaiverEvent =
  EventTypes.InvoiceUpfrontPaymentWaiverEvent;

export type AffidavitUploadedEvent = EventTypes.AffidavitUploadedEvent;
export type CustomIdUpdatedEvent = EventTypes.CustomIdUpdatedEvent;
export type AffidavitReUploadedEvent = EventTypes.AffidavitReUploadedEvent;

export type DeadlineOverrideEvent = EventTypes.DeadlineOverrideEvent;
export type InvoiceCreationInitiatedEvent =
  EventTypes.InvoiceCreationInitiatedEvent;
export type InvoiceCreationFailedEvent = EventTypes.InvoiceCreationFailedEvent;

export type CustomerOrganization =
  CustomerOrganizationTypes.CustomerOrganization;
export type Customer = CustomerTypes.Customer;
export type InvoiceMail = CustomerTypes.InvoiceMail;
export * from './discount';
export * from './media';

export type EStripeBalanceTransaction = StripeBalanceTransaction;
export type EStripeCustomer = StripeCustomer;
export type EStripeCustomerBalanceTransaction =
  StripeCustomerBalanceTransaction;
export type EInAppLineItem = InAppLineItem;
export type EStripeConfirmation = StripeConfirmation;
export type EStripeSubscriptionItem = StripeSubscriptionItem;
export type EStripeSource = StripeSource;
export type EStripeBankAccount = StripeBankAccount;
export type EStripeUsageRecord = StripeUsageRecord;
export type EStripePayout = StripePayout;
export type EPayoutData = PayoutData;
export type EStripePaymentMethod = StripePaymentMethod;
export type EStripePaymentMethodCard = StripePaymentMethodCard;
export type EStripePaymentMethodUSBankAccount =
  StripePaymentMethodUSBankAccount;
export type EStripeTransfer = StripeTransfer;

export type EDwollaTransfer = DwollaTransfer;
export type EDwollaFundingSource = DwollaFundingSource;

export type ESubscription = Subscription;
export type EOrganization = Organization;
export type XMLSyncExportSettings = EXMLSyncExportSettings;
export type BuildIntegrationExportSettings = EBuildIntegrationExportSettings;
export type ERef<T> = Ref<T>;
export type ESnapshot<T> = Snapshot<T>;
export type EQuerySnapshot<T> = QuerySnapshot<T>;
export type ESnapshotExists<T> = SnapshotExists<T>;
export type EUnsubscribe = Unsubscribe;
export type ECollectionRef<T> = CollectionRef<T>;
export type ERefFactory = RefFactory;
export type EQuery<T> = Query<T>;
export type EFirebaseContext = FirebaseContext;
export type ESignInProvider = SignInProvider;
export type EBulkWriter<T> = BulkWriter<T>;
export type ETransaction = Transaction;
export type EFieldValue<T> = FieldValue<T>;
export type EFieldValues = FieldValues;
export type EDocumentData<T> = DocumentData<T>;
export type EPartialDocumentData<T> = PartialDocumentData<T>;
export type EAdjudicationArea = AdjudicationArea;

export type EInherits<T> = Inherits<T>;

export type EUser = User;
export type EUserNotificationType = UserNotificationType;
export type EUserNotificationBaseType = UserNotificationBaseType;
export type EUserSavedInfo = UserSavedInfo;
export type EInvite = Invite;
export type ENotice = Notice;
export type ENoticeDraft = Draft;
export type EPreviewNotice = PreviewNotice;
export type EDisplayParams = DisplayParams;
export type ECrop = Crop;
export type InviteEmailsRequest = IEmailsRequest;
export type BulkDownloadResponse = IBulkResponse;
export type BulkDownloadNoticeGrouping = IBulkDownloadNoticeGrouping;
export type BulkDownloadRequest = IBulkDownloadRequest;
export type NotFoundReponse = INotFoundResponse;
export type DisplayImageRequest = IDisplayImageRequest;
export type EPublicNotice = PublicNotice;
export type MailDelivery = Mail;
export type EAddress = Address;
export type EPayToAddress = PayToAddress;
export type ENotificationSettings = NotificationSettings;
export type ENotification = Notification;
export type EAttachment = Attachment;
export type ERate = Rate;
export type EInvoice = Invoice;
export type EInvoiceLineItem = InvoiceLineItem;
export type EPaywayInvoice = PaywayInvoice;
export type EInvoiceRecipient = InvoiceRecipient;
export type EInvoiceRecipientEmail = InvoiceRecipientEmail;
export type EInvoiceRecipientMail = InvoiceRecipientMail;
export type ETemplate = Template;
export type ETemplateStyles = TemplateStyles;
export type ETransfer = Transfer;
export type EUploadedFileAttr = UploadFileAttrs;
export type PDFPageNDisplay = IPDFPageNDisplay;
export type PDFPageNLiner = IPDFPageNLiner;
export type PDFPageN = IPDFPageN;
export type RenderedPageN = IRenderedPageN;
export type ConvertAPIResponse = IConvertAPIResponse;
export type EmailConfirmation = IEmailConfirmation;
export type ESource = Source;
export type ELabel = Label;
export type TemplateSpecs = ITemplateSpecs;
export type ECardInvoice = CardInvoice;
export type ECardInvoiceLineItem = CardInvoiceLineItem;
export type ECard = Card;
export type ECardTransaction = CardTransaction;
export type EInvoiceItem = InvoiceItem;
export type EPayout = Payout;
export type ENoticeFile = NoticeFile;
export type ENoticeFileNoticeContent = NoticeFileNoticeContent;
export type ENoticeFileSupplementalContent = NoticeFileSupplementalContent;
export type ENoticeFileLegacySchema = NoticeFileLegacySchema;
export type FirebaseTimestamp = firebase.default.firestore.Timestamp;
export type FirebaseUser = firebase.default.User;
export type FirebaseUploadTaskSnapshot =
  firebase.default.storage.UploadTaskSnapshot;
export type IntegrationOptions = EIntegrationOptions;
export type IntegrationTrigger = EIntegrationTrigger;

export type { default as ModularSize } from './modularSize';

export type { AdRate } from './rates';

export type EDisplaySite = DisplaySite;
export type EUploadID = UploadID;
export type OrganizationUploadID = EOrganizationUploadID;
export type DisplaySiteSubdomain = EDisplaySiteSubdomain;

export type LaunchDarklyFlags = ELaunchDarklyFlags;
export type FeatureFlagOptions<FlagType> = EFeatureFlagOptions<FlagType>;
export type LaunchDarklyContext = ELaunchDarklyContext;

export type EMigration = Migration;

export type EDeadline = Deadline;

export type ExternalUploadSettings<T extends ExternalUploadConfig> =
  EExternalUploadSettings<T>;
export type ErrorReporter = IErrorReporter;
export type EJoinRequest = JoinRequest;

export type EFtpFile = FtpFile;

export * from './integrations/caches';
export type EData = Data;
export type EUpdateSettingRequest = UpdateSettingRequest;

/**
 * Custom type guard which can be used to help the TS compiler narrow Firebase types.
 */
export const exists = <T>(
  snap: ESnapshot<T> | undefined | null
): snap is ESnapshotExists<T> => {
  return snap?.exists === true;
};

export const userConverter = {
  toFirestore(user: EUser): firebase.default.firestore.DocumentData {
    return { ...user };
  },
  fromFirestore(snapshot: any): EUser {
    return snapshot as unknown as EUser;
  }
};

export const OrganizationConverter = {
  toFirestore(user: EUser): firebase.default.firestore.DocumentData {
    return { ...user };
  },
  fromFirestore(snapshot: any): EUser {
    return snapshot as unknown as EUser;
  }
};

export type Awaited<T> = T extends PromiseLike<infer U> ? U : T;
