import ColumnDatePicker from 'components/ColumnDatePicker';
import FullScreenModal from 'components/FullScreenModal';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { Label } from 'lib/components/Label';
import { TextField } from 'lib/components/TextField';
import { Product, TimezoneType } from 'lib/enums';
import { EOrganization, ESnapshotExists } from 'lib/types';
import { DeadlineSettings } from 'lib/types/deadlines';
import { columnObjectsAreEqual } from 'lib/utils/stringify';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { PRODUCT_TO_NAME } from 'lib/enums/Product';
import { getTimeOptionsForDeadlines } from 'lib/utils/deadlines';
import { DeadlinePreview } from '../Preview';
import {
  ScheduleChangesFormItem,
  ScheduleChangesTableItem,
  getScheduleChangeSettingsForNewPublicationDate,
  scheduleChangesTableDataToPublisherDeadline
} from './helpers';

const PREVIEW_TIME_FORMAT = 'HH:mm';

const getFixedPreviewDeadline = (
  updatedScheduleChange: ScheduleChangesTableItem,
  deadlineTime: string
) =>
  moment(
    `${moment(updatedScheduleChange.deadlineDate).format(
      'YYYY-MM-DD'
    )}${deadlineTime}`,
    `YYYY-MM-DD${PREVIEW_TIME_FORMAT}`
  ).toDate();

type ScheduleChangeFormProps = {
  productLine: Product;
  activeOrganization: ESnapshotExists<EOrganization>;
  scheduleChange: ScheduleChangesFormItem;
  onSubmit: (
    dayLabel: string,
    updatedScheduleChange: ScheduleChangesTableItem,
    updatedDeadlineSettings: DeadlineSettings
  ) => Promise<void>;
  onClose: () => void;
};

export function ScheduleChangeForm({
  productLine,
  activeOrganization,
  scheduleChange,
  onSubmit,
  onClose
}: ScheduleChangeFormProps) {
  const productName = PRODUCT_TO_NAME[productLine].plural.toLocaleLowerCase();

  const [updatedScheduleChange, setUpdatedScheduleChange] =
    useState(scheduleChange);
  const [deadlineTime, setDeadlineTime] = useState(
    moment(scheduleChange.deadlineDate).format(PREVIEW_TIME_FORMAT)
  );

  const [updating, setUpdating] = useState(false);

  const currDeadlineSettings =
    scheduleChangesTableDataToPublisherDeadline(scheduleChange);
  const updatedDeadlineSettings = scheduleChangesTableDataToPublisherDeadline(
    updatedScheduleChange,
    deadlineTime
  );
  const disableSave =
    updating ||
    (!!scheduleChange.isEdit &&
      columnObjectsAreEqual(currDeadlineSettings, updatedDeadlineSettings));

  const dayLabel = moment(updatedScheduleChange.publicationDate).format(
    'dddd, M/D/YY'
  );
  const timezoneLabel =
    TimezoneType.by_key(activeOrganization.data().iana_timezone)?.abbrev ?? '';

  useEffect(() => {
    if (scheduleChange.isEdit) {
      return;
    }

    const { publicationDate } = updatedScheduleChange;

    setUpdatedScheduleChange(
      getScheduleChangeSettingsForNewPublicationDate(
        publicationDate,
        activeOrganization
      )
    );
  }, [updatedScheduleChange.publicationDate.getTime()]);

  const saveScheduleChange = async () => {
    setUpdating(true);

    try {
      await onSubmit(dayLabel, updatedScheduleChange, updatedDeadlineSettings);
    } finally {
      setUpdating(false);
    }
  };

  const fixedPreviewDeadline = getFixedPreviewDeadline(
    updatedScheduleChange,
    deadlineTime
  );

  return (
    <FullScreenModal
      id="schedule-change-form"
      submittable={{
        buttonText: scheduleChange.isEdit
          ? 'Save Changes'
          : 'Create Schedule Change',
        disabled: disableSave,
        onSubmit: saveScheduleChange
      }}
      previewable={{
        header: { title: 'Preview' },
        renderPreview: () => (
          <DeadlinePreview
            deadlineSettings={updatedDeadlineSettings}
            timezone={'America/New_York'}
            fixedPreviewDate={updatedScheduleChange.publicationDate}
            fixedPreviewDeadline={fixedPreviewDeadline}
          />
        ),
        withBorder: true
      }}
      headerText={
        scheduleChange.isEdit ? 'Edit Schedule Change' : 'New Schedule Change'
      }
      onClose={onClose}
    >
      <CardGridLayout
        header={{
          title: 'Schedule Change',
          description: `Configure the deadline day and time for ${
            scheduleChange.isEdit ? `the ${dayLabel}` : 'a specific'
          } publication date.`
        }}
      >
        {!scheduleChange.isEdit && (
          <GridInput fullWidth>
            <Label id="edit-schedule-change-publication-date">
              <p className="mb-3">
                Which publication date needs a schedule change?
              </p>
              <ColumnDatePicker
                format="M/d/yy"
                momentFormat="M/D/YY"
                placeholderText="M/D/YY"
                value={updatedScheduleChange.publicationDate}
                onChange={newDate => {
                  if (newDate) {
                    setUpdatedScheduleChange({
                      ...updatedScheduleChange,
                      publicationDate: newDate
                    });
                  }
                }}
                shouldDisableDate={() => {
                  return false; // Allow past dates
                }}
                className="p-3 border-column-gray-200 focus:border-column-primary-500 focus:shadow-outline-column-primary"
              />
            </Label>
          </GridInput>
        )}

        <SwitchControlledCard
          labelProps={{
            label: `Will you publish on ${dayLabel}?`,
            description: `Enable publication to allow customers to place ${productName} for publication on ${dayLabel}.`,
            value: updatedScheduleChange.publish,
            onChange: publish =>
              setUpdatedScheduleChange({ ...updatedScheduleChange, publish })
          }}
          header="Deadline Preferences"
        >
          <GridInput>
            <Label
              id="edit-schedule-change-deadline-date"
              disabled={!updatedScheduleChange.publish}
            >
              <p className="mb-3">What day is the deadline?</p>
              <ColumnDatePicker
                format="M/d/yy"
                momentFormat="M/D/YY"
                placeholderText="M/D/YY"
                disabled={!updatedScheduleChange.publish}
                value={updatedScheduleChange.deadlineDate}
                onChange={newDate => {
                  if (newDate) {
                    setUpdatedScheduleChange({
                      ...updatedScheduleChange,
                      deadlineDate: newDate
                    });
                  }
                }}
                shouldDisableDate={date => {
                  if (date) {
                    return moment(date).isSameOrAfter(
                      moment(updatedScheduleChange.publicationDate),
                      'd'
                    );
                  }

                  return false;
                }}
                className="p-3 border-column-gray-200 focus:border-column-primary-500 focus:shadow-outline-column-primary"
              />
            </Label>
          </GridInput>
          <GridInput>
            <ColumnSelect
              id="edit-schedule-change-deadline-time"
              labelText={`What time is the deadline? (${timezoneLabel})`}
              disabled={!updatedScheduleChange.publish}
              options={getTimeOptionsForDeadlines()}
              value={deadlineTime}
              onChange={newVal => setDeadlineTime(newVal)}
            />
          </GridInput>
          {productLine === Product.Notice && (
            <GridInput fullWidth>
              <TextField
                id="edit-deadline-display-offset"
                labelText="How many hours earlier is the deadline for display ads?"
                noteText="Adjust this field if deadline is earlier for display ads than for liner ads."
                type="number"
                min={0}
                placeholder="0"
                disabled={!updatedScheduleChange.publish}
                value={`${updatedScheduleChange.displayOffset ?? ''}`}
                onChange={newVal =>
                  setUpdatedScheduleChange({
                    ...updatedScheduleChange,
                    displayOffset: Number(newVal)
                  })
                }
              />
            </GridInput>
          )}
        </SwitchControlledCard>
      </CardGridLayout>
    </FullScreenModal>
  );
}
