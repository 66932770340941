import { useEffect, useMemo, useState } from 'react';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { useAppSelector } from 'redux/hooks';
import {
  selectActiveOrganization,
  selectIsPublisher,
  selectShowAllOrgsNotices,
  selectUser
} from 'redux/auth';
import { SORT_ASCENDING, SORT_DESCENDING } from 'lib/types/searchable';
import { safeStringify } from 'lib/utils/stringify';
import { debounce } from 'lodash';
import { TabOption } from 'lib/components/Tabs';
import { Alert } from 'lib/components/Alert';
import { apiPost } from 'api/typed';
import { Product } from 'lib/enums';
import { ColumnService } from 'lib/services/directory';
import { ColumnButton } from 'lib/components/ColumnButton';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { useNavigate } from 'react-router-dom';
import { getDynamicRoute } from 'lib/frontend/utils/router';
import { CLASSIFIED_ROUTES } from 'router/routes';
import { DEFAULT_ORDER_FILTER, getOrderFilters } from './filters/helpers';
import {
  ORDER_STATUS_TABS,
  IN_PROGRESS_ORDERS_TAB,
  COMPLETED_ORDERS_TAB,
  CANCELLED_ORDERS_TAB,
  DRAFT_ORDERS_TAB
} from './tabs';
import AdsTable from './AdsTable';
import { AdsTableColumns } from './types';

const getTableColumns = (
  isPublisher: boolean,
  tableTab: TabOption,
  showAllOrgsNotices = false
) => {
  const isDraftView = tableTab.id === DRAFT_ORDERS_TAB.id;

  const allowOrderDuplication =
    !isDraftView && getBooleanFlag(LaunchDarklyFlags.ENABLE_DUPLICATE_ORDERS);

  const tableColumns = [
    {
      label: AdsTableColumns.LISTING_NAME,
      enabled: true
    },
    {
      label: AdsTableColumns.AD_DEADLINE,
      enabled: true
    },
    {
      label: AdsTableColumns.CUSTOMER_NAME,
      enabled: isPublisher
    },
    {
      label: AdsTableColumns.PUBLICATION,
      enabled: !isPublisher
    },
    {
      label: AdsTableColumns.NEWSPAPER_NAME,
      enabled: isPublisher && showAllOrgsNotices
    },
    {
      label: AdsTableColumns.CATEGORY,
      enabled: true
    },
    {
      label: AdsTableColumns.PUBLICATION_DATE,
      enabled: true
    },
    {
      label: AdsTableColumns.STATUS,
      enabled: tableTab.id === IN_PROGRESS_ORDERS_TAB.id
    },
    {
      label: AdsTableColumns.ACTIONS,
      enabled: allowOrderDuplication
    }
  ];

  return tableColumns
    .filter(column => column.enabled)
    .map(column => column.label);
};

function ClassifiedsTableContainer() {
  const navigate = useNavigate();
  const activeOrganization = useAppSelector(selectActiveOrganization);
  const isPublisher = useAppSelector(selectIsPublisher);
  const showAllOrgsNotices = useAppSelector(selectShowAllOrgsNotices);
  const user = useAppSelector(selectUser);
  const [selectedAdTableTab, setSelectedAdTableTab] = useState(
    ORDER_STATUS_TABS[0]
  );
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const [rowFilter, setRowFilter] = useState(DEFAULT_ORDER_FILTER);
  const allowedOrganizations = user?.data().allowedOrganizations;

  const loadClassifieds = async () => {
    if (!selectedAdTableTab || !user) {
      return [];
    }

    const filters = getOrderFilters({
      product: Product.Classified,
      activeOrganization,
      user,
      selectedAdTableTab,
      tableFilters: rowFilter,
      isPublisher,
      allowedOrganizations,
      showAllOrgsNotices
    });

    const result = await apiPost('search/orders', {
      search: debouncedSearchTerm,
      filters,
      sort: [
        {
          addeadline:
            selectedAdTableTab.id === IN_PROGRESS_ORDERS_TAB.id
              ? SORT_ASCENDING
              : SORT_DESCENDING
        }
      ]
    });

    if (!result.success) {
      throw result.error;
    }

    return result.results;
  };

  const {
    isLoading,
    isError,
    value: classifiedsTableData,
    invalidateData: refreshTableData
  } = useAsyncEffect({
    fetchData: loadClassifieds,
    dependencies: [
      activeOrganization?.id,
      !!user,
      isPublisher,
      selectedAdTableTab?.id,
      debouncedSearchTerm,
      safeStringify(rowFilter)
    ],
    errorConfig: {
      service: ColumnService.ORDER_MANAGEMENT,
      message: 'Error loading classifieds table',
      tags: { activeOrganization: activeOrganization?.id || '' }
    }
  });

  const updateSearch = useMemo(
    () =>
      debounce(value => {
        setDebouncedSearchTerm(value);
      }, 400),
    []
  );

  useEffect(() => {
    updateSearch(searchTerm);
  }, [searchTerm]);

  return (
    <>
      {isError && (
        <Alert
          id="classifieds-table-error"
          status="error"
          title={'There was an error loading the data. Please try again.'}
        />
      )}
      <AdsTable
        product={Product.Classified}
        ads={classifiedsTableData ?? []}
        tabs={ORDER_STATUS_TABS}
        columns={getTableColumns(
          isPublisher,
          selectedAdTableTab,
          showAllOrgsNotices
        )}
        header={{
          title: {
            [IN_PROGRESS_ORDERS_TAB.id]: 'In progress classifieds',
            [COMPLETED_ORDERS_TAB.id]: 'Completed classifieds',
            [DRAFT_ORDERS_TAB.id]: 'Drafted classifieds',
            [CANCELLED_ORDERS_TAB.id]: 'Cancelled classifieds'
          }[selectedAdTableTab.id],
          subtitle: {
            [IN_PROGRESS_ORDERS_TAB.id]:
              "Classifieds that haven't completed their final run date.",
            [COMPLETED_ORDERS_TAB.id]:
              'Classifieds that have passed their final run date.',
            [DRAFT_ORDERS_TAB.id]:
              'Classifieds that were not fully submitted but can still be completed.',
            [CANCELLED_ORDERS_TAB.id]:
              'Classifieds that were cancelled either by your organization or the client.'
          }[selectedAdTableTab.id],
          additionalContent: (
            <ColumnButton
              type="button"
              buttonText="Place a classified"
              startIcon={<PlusCircleIcon className="w-5 h-5" />}
              onClick={() => {
                navigate(getDynamicRoute(CLASSIFIED_ROUTES.PLACE, { id: '' }));
              }}
              primary
            />
          )
        }}
        selectedAdTableTab={selectedAdTableTab}
        setSelectedAdTableTab={setSelectedAdTableTab}
        loading={isLoading}
        activeOrganization={activeOrganization}
        isPublisher={isPublisher}
        setSearchTerm={setSearchTerm}
        rowFilter={rowFilter}
        setRowFilter={setRowFilter}
        refreshTableData={refreshTableData}
      />
    </>
  );
}

export default ClassifiedsTableContainer;
