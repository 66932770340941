import { isOrderRate, OrderRate } from '../../types/rates';
import { FilingTypeVisibility } from '../../enums';
import { SnapshotModel } from '..';
import { Collections } from '../../constants';
import {
  BadRequestError,
  ColumnError,
  NotFoundError,
  wrapErrorAsColumnError
} from '../../errors/ColumnErrors';
import { safeGetOrThrow } from '../../safeWrappers';
import { ESnapshotExists } from '../../types';
import { OrderFilingType } from '../../types/filingType';
import { ResponseOrError, wrapError, wrapSuccess } from '../../types/responses';
import {
  findLayoutById,
  getDefaultLayoutsByFilingType
} from '../../orders/layouts';
import { Layout } from '../../types/layout';
import { Ad } from '../../types/ad';
import { LayoutModel } from './layoutModel';
import { getLaunchDarklyContext } from '../../utils/flags';
import { LaunchDarklyFlags } from '../../types/launchDarklyFlags';

/**
 * Sort by:
 * 1) Default layouts before display layouts
 * 2) Number of columns, smallest number of columns first
 * 3) Number of photos, smallest number of photos first
 */
const compareLayouts = (a: Layout, b: Layout) => {
  if (a.tag !== b.tag) {
    if (a.tag === 'default') {
      return -1;
    }
    if (b.tag === 'default') {
      return 1;
    }
  }
  if (a.columns !== b.columns) {
    return a.columns - b.columns;
  }

  const layoutA = new LayoutModel(a);
  const layoutB = new LayoutModel(b);

  if (layoutA.imageCount !== layoutB.imageCount) {
    return layoutA.imageCount - layoutB.imageCount;
  }

  return 0;
};

function populateLayoutsForFilingType(
  filingType: OrderFilingType
): Layout[] | null {
  const { supportedLayoutIds } = filingType;
  const hasSupportedLayouts =
    supportedLayoutIds && supportedLayoutIds?.length > 0;

  if (hasSupportedLayouts) {
    const populatedLayouts = supportedLayoutIds
      .map(findLayoutById)
      .filter((layout): layout is Layout => !!layout);

    if (populatedLayouts.length > 0) {
      return populatedLayouts;
    }
  }

  return null;
}

type GetRateParams = {
  isDisplayLayout?: boolean;
};

export class FilingTypeModel extends SnapshotModel<
  OrderFilingType,
  typeof Collections.filingTypes
> {
  get type() {
    return Collections.filingTypes;
  }

  public async getRate(
    params?: GetRateParams
  ): Promise<ResponseOrError<ESnapshotExists<OrderRate>, ColumnError>> {
    const enableDisplayRates =
      await getLaunchDarklyContext().getBooleanFeatureFlag(
        LaunchDarklyFlags.ENABLE_ORDER_FILING_TYPE_DISPLAY_RATE_CONFIGURATION,
        {
          type: 'anonymous',
          defaultValue: false
        }
      );

    const shouldLoadDisplayRate =
      enableDisplayRates &&
      params?.isDisplayLayout &&
      this.modelData.rateConfiguration?.defaultDisplayRate;
    const rateRef = shouldLoadDisplayRate
      ? this.modelData.rateConfiguration?.defaultDisplayRate
      : this.modelData.rate;

    const { response: rateSnap, error: rateError } = await safeGetOrThrow(
      rateRef
    );
    if (rateError) {
      return wrapErrorAsColumnError(rateError, NotFoundError);
    }

    const rate = rateSnap.data();

    if (!isOrderRate(rate)) {
      return wrapError(
        new BadRequestError('Received rate inapplicable to order')
      );
    }

    return wrapSuccess(rateSnap as ESnapshotExists<OrderRate>);
  }

  get initialPublicationDateCount() {
    const initialPublicationDateCount =
      this.modelData.publicationDateSettings?.initialPublicationDates ||
      this.modelData.publicationDateSettings?.minimumPublicationDates ||
      1;

    return initialPublicationDateCount;
  }

  getSortedSupportedLayouts(adData: Partial<Ad>): Layout[] {
    const populatedLayouts = populateLayoutsForFilingType(this.modelData);

    const supportedLayouts =
      populatedLayouts || getDefaultLayoutsByFilingType(this.modelData, adData);

    return supportedLayouts.sort(compareLayouts);
  }

  public isVisibleToUser(isUserPublisher: boolean) {
    const availableVisibilities = [FilingTypeVisibility.all_users.value];

    if (isUserPublisher) {
      availableVisibilities.push(FilingTypeVisibility.publisher_only.value);
    }

    return availableVisibilities.includes(
      this.modelData.visibility || FilingTypeVisibility.disabled.value
    );
  }
}
